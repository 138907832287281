import { ReactNode } from "react";
import cx from 'classnames';
import s from './ScrollToViewButton.module.scss';
import { useScrollIntoView } from '~/Accounts/hooks/useScrollIntoView';
import { ViewType } from '~/Accounts/types';

type ScrollToViewButtonProps = {
  view: ViewType,
  className?: string,
  children?: ReactNode,
  onScroll?: any,
};

const ScrollToViewButton = (props: ScrollToViewButtonProps) => {
  const {
    view,
    className,
    children,
    onScroll,
  } = props;

  const { scroll } = useScrollIntoView();

  const handleClick = () => {
    scroll(view)
    // hacky, but works: we need to hide section on scroll end
    onScroll && setTimeout(() => { onScroll(view) }, 600);
  }

  return (
    <button className={cx(s.root, className)} onClick={handleClick}>
      {children}
    </button>
  );
}


export default ScrollToViewButton;
