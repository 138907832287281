import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';

import Back from '@/shared/ui/Back';
import CardTitle from '@/shared/ui/CardTitle';
import ButtonText from '@/shared/ui/ButtonText';
import Box from '@/shared/ui/Box';
import { useGetWatcherLinksQuery } from './api';
import type { WatcherLink } from './types';
import WatcherLinkCard from './ui/WatcherLinkCard';
import WatcherLinkLoader from './ui/WatcherLinkLoader';
import { useWatcherLink } from './hooks/useWatcherLink';
import s from './WatcherLink.module.scss';

type WatcherLinkProps = {}

const WatcherLink = (props: WatcherLinkProps) => {
  const {} = props;

  const { data: wlList = [], isLoading, isSuccess } = useGetWatcherLinksQuery(null);
  const isEmpty = isSuccess && wlList?.length === 0;
  
  const { toggleCreateWatcherLinkModal } = useWatcherLink();

  const handleToggleCreateModal = (show: boolean) => { toggleCreateWatcherLinkModal(show); };

  return (
    <div className={s.root}>
      <div className="flex justify-between mb-10 items-center mx-4">
        <Back link="/">
          <FormattedMessage id="header.p.business"/>
        </Back>
      </div>

      <section className='max-w-[418px] mx-auto'>
        <CardTitle 
          title={<FormattedMessage id="wl.title"/>}
          action={
            <ButtonText
              onClick={() => handleToggleCreateModal(true)}
            >
              <FormattedMessage id="wl.create"/>
            </ButtonText>
          }
        />

        <div className="flex flex-col gap-2">
          {isLoading ? (
            <WatcherLinkLoader/>
          ) : (
            isEmpty ? (
              <Box withBorder={false} className="text-xs text-center text-white/[0.6]">
                <FormattedMessage id="wl.no.data"/>
              </Box>
            ) : wlList.map((wl: WatcherLink) => {
              return (
                <WatcherLinkCard key={wl.id} wlData={wl}/>
              )
            })
          )}
        </div>
      </section>
    </div>
  );
}

export default WatcherLink;
