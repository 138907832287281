import React from 'react';
import cx from "classnames";

type MainLayoutProps = {
  className?: string
  children: React.ReactNode
}

const MainLayout = (props: MainLayoutProps) => {
  const { className, children } = props;
  return (
    <main className={cx('container mx-auto pt-4 md:pt-10 pb-10 overflow-x-hidden', className)}>
      {children}
    </main>
  );
}

export default MainLayout;
