
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Account, ViewType } from './types';

const initialState:any = {
  view: 'main',
  selectedAccount: null,
  selectedPayoutCoin: null,
  modals: {
    showCreateAccount: false,  
    showDeactivateAccount: false,
    showWithdrawalAddress: false,
    showWithdrawalAddressEdit: false,
  }
}

export const accountsSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    toggleActive: (state, action:PayloadAction<string | string[] | undefined>) => {
      if (action?.payload === state.selectedAccount) {
        state.selectedAccount = null;
        return;
      }

      state.selectedAccount = action.payload;
    },
    toggleSelectedPayoutCoin: (state, action:PayloadAction<string | null>) => {
      if (action?.payload === state.selectedPayoutCoin) {
        state.selectedPayoutCoin = null;
        return;
      }

      state.selectedPayoutCoin = action.payload;
    },
    toggleCreateAccountModal: (state, action:PayloadAction<boolean>) => {
      state.modals.showCreateAccount = action.payload;
    },
    toggleDeactivateAccountModal: (state, action:PayloadAction<boolean>) => {
      state.modals.showDeactivateAccount = action.payload;
    },
    toggleWithdrawalAddressModal: (state, action) => {
      const { 
        show = false, 
        isEdit = false 
      } = action.payload;
      state.modals.showWithdrawalAddress = show;
      state.modals.showWithdrawalAddressEdit = isEdit;
    },
    hideAllModals: (state) => {
      Object.keys(state.modals).forEach((key) => {
        state.modals[key] = false;
      })
    },
    switchView: (state, action:PayloadAction<ViewType>) => {
      state.view = action.payload
    },
  },
})

export const { 
  toggleActive,
  toggleSelectedPayoutCoin,
  toggleCreateAccountModal,
  toggleDeactivateAccountModal,
  toggleWithdrawalAddressModal,
  hideAllModals,
  switchView,
} = accountsSlice.actions;

export default accountsSlice.reducer;
