import api from '@/global/api';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCryptoCurrencies: build.query({
      query: () => ({
        url: `/v1/currencies`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCryptoCurrenciesQuery,
} = extendedApi;

export const {
  getCryptoCurrencies,
} = extendedApi.endpoints;
