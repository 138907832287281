import React, { useState, useEffect } from 'react';

// Custom hook to check if scroll is not at the start and not at the end
type ScrollBoundaries = {
  isNotAtStart: boolean;
  isNotAtEnd: boolean;
}

const useScrollBoundaries = (ref: React.RefObject<HTMLDivElement>): ScrollBoundaries => {
  const [isNotAtStart, setIsNotAtStart] = useState(false);
  const [isNotAtEnd, setIsNotAtEnd] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;

        if (scrollHeight > clientHeight) {
          setIsNotAtStart(scrollTop > 0);
          setIsNotAtEnd(scrollTop < scrollHeight - clientHeight);
        } else {
          // If no scroll is present, set both values to false
          setIsNotAtStart(false);
          setIsNotAtEnd(false);
        }
      }
    };

    const element = ref?.current;

    if (element) {
      element.addEventListener('scroll', onScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll);
      }
    };
  }, [ref]);

  return { isNotAtStart, isNotAtEnd };
}

export default useScrollBoundaries;
