import React from 'react';
import LoaderDots from "@/shared/ui/LoaderDots";
import cx from 'classnames';
import s from './Button.module.scss';

type ButtonProps = {
  className?: string;
  onClick?: (...props: any) => void;
  disabled?: boolean;
  size?: 'sm' | 'md';
  type?: 'button' | 'submit' | 'reset';
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  text?: string;
  tag?: any;
  colorSchema?: 'primary' | 'secondary';
  isLoading?: boolean;
  isActive?: boolean;
  ref?: any;
  children?: React.ReactNode;
  [x:string]: any;
}

const Button = (props: ButtonProps) => {
  const {
    className = '',
    onClick,
    disabled = false,
    size = 'sm',
    tag: Tag = 'button',
    type = 'button',
    text,
    colorSchema = 'secondary',
    prefixIcon,
    suffixIcon,
    isLoading = false,
    isActive = false,
    children,
    ref,
    ...restProps
  } = props;

  const content = text || children;

  const sizes = {
    sm: s.sm,
    md: s.md,
  };

  const variants = {
    solid: s.solid,
    text: s.text,
    outline: s.outline,
  };

  const colorSchemes = {
    secondary: s.secondary,
    primary: s.primary,
  };

  const buttonClasses = cx(
    s.button,
    disabled && s.disabled,
    colorSchemes[colorSchema],
  );

  const btnProps = Tag === 'button' ? {
    type,
    disabled,
  } : {};

  const innerClasses = cx(
    s.inner,
    sizes[size],
  );

  return (
    <Tag
      className={cx(buttonClasses, className, {
        [s.isActive]: isActive,
        [s.isLoading]: isLoading,
      })}
      ref={ref}
      onClick={onClick}
      {...btnProps}
      {...restProps}
    >
      <span className={innerClasses}>
        {isLoading ? (
          <span className={s.loadingDots}>
            <LoaderDots isLoading isDark={false} />
          </span>
        ) : (
          <>
            {prefixIcon ? (
              <span className={s.prefix}>
                {prefixIcon}
              </span>
            ) : null}
            <span className={s.content}>{content}</span>
            {suffixIcon ? (
              <span className={s.suffix}>
                {suffixIcon}
              </span>
            ) : null}
          </>
        )}
      </span>
    </Tag>
  );
}

export default Button;
