import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import _get from 'lodash/get';
import cx from 'classnames';

import type { Account } from '~/Accounts/types';
import { usernameSelector } from '@/processes/Auth';
import s from './AccountListItem.module.scss';
import { useGetAccountByNameQuery, extendedApi } from '~/Accounts/api';
import { deletePendingAccountFromStorage } from '~/Accounts/utils/pendingAccounts';

type AccountListItemPendingProps = {
  account: Account,
};
const POLLING_INTERVAL = 500;
const MAX_POLLING_INTERVAL = 3000;

/**
 * 1. Set polling state to localStorage: mainacc.pendingAccs = [{ name, desc }]
 * 2. If account in mainacc.pendingAccs exists, start polling api
 * 3. When api return details with account, remove acc from storage 
 * 4. Dispatch state update to set account.isPending = false
 */
const AccountListItemPending = (props: AccountListItemPendingProps) => {
  const {
    account,
  } = props;
  const dispatch = useDispatch();
  const username = useSelector(usernameSelector);
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL)

  const {
    name = '',
    about = '',
  } = account;

  const { data } = useGetAccountByNameQuery(name, {
    skip: !name,
    pollingInterval,
  })
  const isAccountCreated = Boolean(_get(data, 'account.name', null));
  const avatarLetter = name[0] || '';

  useEffect(() => {
    // increase polling interval +500ms up to 3sec max
    if (pollingInterval < MAX_POLLING_INTERVAL) {
      setPollingInterval(pollingInterval + POLLING_INTERVAL);
    }
    if (isAccountCreated && username && name) {
      // stop polling interval when acc created
      setPollingInterval(0);
      // remove account from storage
      deletePendingAccountFromStorage(username, name);
      // dispatch action to update cache
      dispatch(
        extendedApi.util.updateQueryData('getAccountsDetailsBatch', null, (draft) => {
          const updatedIndex = draft.findIndex((account: Account) => account.name === name);
          if (updatedIndex !== -1) {
            draft[updatedIndex].isPending = false;
          }
        })
      )
    }
  }, [username, isAccountCreated, name]) // eslint-disable-line

  return (
    <button className={cx(s.root, s.pending)}>
      <div
        className={cx(s.avatar, 'font-light leading-3 flex items-center justify-center text-center p2 rounded-md')}
      >
        <div className='pt-1'>{avatarLetter}</div>
      </div>
      <div className={cx(s.texts, 'text-left flex-1 basis-1/2 font-medium')}>
        <div className=""></div>
        <div className={cx(s.name, 'leading-5 mb-1')}>{name}</div>
        <div className={cx(s.description, 'text-xs')}>
          <FormattedMessage id="sub.acc.creating"/>
        </div>
      </div>
      <div className={cx(s.values, 'text-right flex-1 basis-1/3 font-medium')}>
        <div className={cx(s.balance, 'leading-5 mb-1 whitespace-nowrap')}>
        </div>
        <div className={cx(s.hashrate, 'text-xs')}>
        </div>
      </div>
    </button>
  );
}

export default AccountListItemPending;
