import { useEffect } from 'react';
import _toUpper from 'lodash/toUpper';
import _get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl'
import useURLQuery from '@/shared/hooks/useURLQuery'
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';
import Input from '@/shared/ui/Input';
import RadioGroup from '@/shared/ui/RadioGroup';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useDispatch, useSelector } from 'react-redux';
import { toggleWithdrawalAddressModal, toggleActive, toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { 
  selectSelectedAccount, 
  selectSelectedAccountId,
  selectPayoutAddress, 
  selectShowWithdrawalAddressModal, 
  selectSelectedPayoutCoin,
} from '~/Accounts/Accounts.selectors';
import { useCreatePayoutAddressMutation, extendedApi } from '~/Accounts/api';
import CoinLabel from '@/shared/ui/CoinLabel';
import { setConfirmationCallbackState } from '@/features/Confirmation';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import { useValidateAddress } from '~/Accounts/hooks/useValidateAddress';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';

const NAME_MAX_LENGTH = 50;

const schema = z.object({
  name: z.string().max(NAME_MAX_LENGTH),
  address: z.string(),
  frequency: z.string(),
});

type ValidationSchema = z.infer<typeof schema>;
type AddEditWithdrawalAddressModalProps = {}

const states = {
  edit: {
    gtmActionPart: 'edit',
    title: 'edit.address',
    callbackMessage: 'withdrawal.copied.toast.text.updated',
  },
  add: {
    gtmActionPart: 'add',
    title: 'add.address',
    callbackMessage: 'withdrawal.copied.toast.text.added',
  },
};

const AddEditWithdrawalAddressModal = (props: AddEditWithdrawalAddressModalProps) => {
  const intl = useIntl();
  const { query, getURLQueryCoin } = useURLQuery();
  const { accountFromUrl } = useSupportMiner();
  const dispatch = useDispatch();
  const { isShow, isEdit} = useSelector(selectShowWithdrawalAddressModal);
  const { title, callbackMessage, gtmActionPart } = states[isEdit ? 'edit' : 'add'];
  const accountName = useSelector(selectSelectedAccountId);
  const coin = useSelector(selectSelectedPayoutCoin);
  const { address, memo, isCompleted } = useSelector(selectPayoutAddress);
  const { isAddressValid } = useValidateAddress({ coin })
  
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setError,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
    values: {
      name: memo,
      address: address,
      frequency: 'daily',
    }
  });

  const [createPayoutAddress, {
    isLoading,
  }] = useCreatePayoutAddressMutation();

  const handleSubmitCallback = (data: any) => {
    const { name, address } = data;

    if(!isAddressValid(address)) {
      setError('address', { message: intl.formatMessage({ id: 'error.invalid.address' }) })
      return;
    }

    dispatch(setConfirmationCallbackState({
      gtmLabel: `withdrow_settings_${gtmActionPart}`,
      successMessage: intl.formatMessage({ id: callbackMessage }, {
        walletName: name,
      }),
      successCallback: () => {
        // clear form fields
        reset();
        sendGtmCustomEvent({
          action: `withdrow_settings_${gtmActionPart}ed`,
          label: {
            coin,
          }
        });
        dispatch(
          extendedApi.util.invalidateTags([{ type: 'PayoutAddress', id: accountName }])
        )
      }
    }));
    createPayoutAddress({
      account: accountName,
      memo: name,
      address,
      network: _toUpper(coin),
    });
  }

  const handleOnClose = () => {
    sendGtmCustomEvent({
      action: `withdrow_settings_${gtmActionPart}_popup_close`,
      label: {
        coin,
      }
    });
    dispatch(toggleWithdrawalAddressModal({ show: false }));
  }

  const handleOnCloseFromCancel = () => {
    sendGtmCustomEvent({
      action: `withdrow_settings_${gtmActionPart}_popup_cancel_click`,
      label: {
        coin,
      }
    });
    dispatch(toggleWithdrawalAddressModal({ show: false }));
  }

  useEffect(() => {
    const queryAction = query?.action;

    const querySource = getURLQueryCoin();

    if (!accountFromUrl || !queryAction) { return; }

    // preselect account & coin
    dispatch(toggleActive(accountFromUrl));
    dispatch(toggleSelectedPayoutCoin(_toUpper(querySource)));
    
    // show modal
    switch (queryAction) {
      case 'addPayoutAddress':
        dispatch(toggleWithdrawalAddressModal({ show: true }));
        break;
      case 'editPayoutAddress':
        dispatch(toggleWithdrawalAddressModal({ show: true, isEdit: true }));
        break;
    }
    
  }, [query, accountFromUrl]) // eslint-disable-line

  useEffect(() => {
    if (isShow) {
      sendGtmCustomEvent({
        action: `withdrow_settings_${gtmActionPart}_popup_show`,
        label: {
          coin,
        }
      });
    }
  }, [isShow]); // eslint-disable-line

  return (
    <Modal show={isShow} onDismiss={handleOnClose}>
      <h3 className='text-2xl text-white mb-4'>
        <FormattedMessage id={title} />
      </h3>

      <p className='text-white/[0.6] text-xs leading-[18px] mb-4'>
        <FormattedMessage 
          id="add.address.desc"
          values={{
            miningAccountName: accountName,
            br: <br/>,
            span: (chunks) => <span className="text-[#FF5F01]">{chunks}</span>
          }}
        />
      </p>

      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Input
          className='mb-4'
          type="text"
          full={true}
          label={intl.formatMessage({ id: 'Name' })}
          error={errors.name?.message}
          maxLength={NAME_MAX_LENGTH}
          {...register('name')}
        />
        <Input
          className='mb-4'
          type="text"
          full={true}
          label={intl.formatMessage({ id: 'Address' })}
          error={errors.address?.message}
          prefix={<CoinLabel withLabel={false} className="w-3" coin={coin}/>}
          {...register('address')}
        />
        <RadioGroup
          items={[
            { value: 'daily', label: intl.formatMessage({ id: 'Daily' })},
            { value: 'weekly', label: intl.formatMessage({ id: 'Weekly' })},
            { value: 'monthly', label: intl.formatMessage({ id: 'Monthly' })},
          ]}
          label={intl.formatMessage({ id: 'Frequency' })}
          full={true}
          isDisabled={true}
          {...register('frequency')}
        />
        <div className="flex justify-end mt-6">
          <Button onClick={handleOnCloseFromCancel} type="button" className='min-w-[88px]'><FormattedMessage id="Cancel" /></Button>
          <Button 
            onClick={() => {
              const address_entered = !!getValues('address');
              sendGtmCustomEvent({
                action: `withdrow_settings_${gtmActionPart}_popup_next_click`,
                label: {
                  coin,
                  address_entered,
                }
              });
            }}
            disabled={isLoading}
            type="submit" 
            className='ml-5 min-w-[88px]' 
            colorSchema='primary'
          >
            <FormattedMessage id="Next" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default AddEditWithdrawalAddressModal;
