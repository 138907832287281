import { POOL_URL, BASE_HOST } from '@/shared/constants/env';

export const NAVIGATION_PRODUCTS_LOGGED_IN = [
  {
    title: 'header.p.hiveon',
    link: 'https://the.hiveos.farm/',
  },
  {
    title: 'header.p.pool',
    link: POOL_URL,
  },
  {
    title: 'header.p.business',
    link: BASE_HOST,
  },
];

export const NAVIGATION_MORE = [
  {
    title: 'header.p.blog',
    link: 'https://hiveon.com/blog/',
  },
  {
    title: 'header.p.forum',
    link: 'https://hiveon.com/forum/',
  },
  {
    title: 'header.p.kb',
    link: 'https://hiveon.com/knowledge-base/',
  },
  {
    title: 'header.p.referral',
    link: 'https://hiveon.com/referral/',
  },
];