import api from '@/global/api';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserinfo: build.query({
      keepUnusedDataFor: 0,
      query: () => ({
        url: `/oauth/userinfo?with_intercom_id=true`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserinfoQuery,
} = extendedApi;

export const {
  getUserinfo,
} = extendedApi.endpoints;
