import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useTimeoutFn, useCopyToClipboard } from 'react-use';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './Copy.module.scss';

import { IconCopy, IconCheckmark } from '@/shared/ui/Icons';

const RESET_TIMEOUT = 1000;

type CopyProps = {
  text: string;
  label?: string;
  className?: string;
  onCopyCallback?: any;
  size?: number;
};

const Copy = (props: CopyProps) => {
  const {
    text = '',
    label = '',
    className = '',
    onCopyCallback,
    size = 14,
    ...restProps
  } = props;

  const [copied, setCopied] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  const resetStatus = () => {
    setCopied(false);
  }

  const [isReady, cancel, reset] = useTimeoutFn(resetStatus, RESET_TIMEOUT);

  const handleCopy = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    
    copyToClipboard(text);
    reset();
    setCopied(true);
    if (typeof onCopyCallback === 'function') {
      onCopyCallback(text);
    }
  }

  return (
    <button 
      onClick={handleCopy} 
      type="button"
      className={cx(s.btn, className, {
        'flex items-center': !!label,
      })} 
      {...restProps}
    >
      {copied 
        ? <IconCheckmark className={s.icon} width={size} height={size} />
        : <IconCopy className={s.icon} width={size} height={size} />
      }
      {
        label ? (
          <span className={cx(s.label, 'ml-1')}>{label}</span>
        ) : null
      }
    </button>
  );
}

export default Copy;
