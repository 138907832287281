import { useRouter } from 'next/router';

import { PAGE_ROUTES } from '@/shared/constants/routes';

const DEFAULT_META = {
  titleKey: 'header.p.business',
};

export const useMeta = () => {
  const router = useRouter();
  const { asPath } = router;

  const meta = PAGE_ROUTES.find(({ path }: any) => asPath.startsWith(path));
  return meta || DEFAULT_META;
}

export default useMeta;