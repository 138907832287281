import { useState } from 'react';
import { FormattedMessage } from 'react-intl'
import Countdown from '@/shared/ui/Countdown';
import { IconInfo } from '@/shared/ui/Icons';
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';

type PayoutsPausedCountdownProps = {
  targetTime: any,
}

const PayoutsPausedCountdown = (props: PayoutsPausedCountdownProps) => {
  const { targetTime } = props;
  const [showModal, setShowModal] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const handleToggleModal = () => {
    setShowModal(!showModal);
  }

  const handleFinish = () => {
    setShowModal(false);
    setIsFinished(true);
  }

  // hide if finished
  if (isFinished) { 
    return null;
  }

  return (
    <>
      <div 
        className='text-white/[0.6] mt-2.5 text-xs w-full flex justify-between items-start'
      >
        <span>
        <FormattedMessage 
          id="payout.paused" 
          values={{
            countdown: <Countdown
              onFinishCallback={handleFinish}
              targetTime={targetTime}
              className='text-[#FF5F01] ml-1'
            />
          }}
        />
        </span>
        <button className='appearance-none mt-0.5' onClick={handleToggleModal}>
          <IconInfo name="info" className="w-6" />
        </button>
      </div>

      <Modal show={showModal} onDismiss={handleToggleModal}>
        <h3 className='text-2xl text-white mb-4'>
          <FormattedMessage 
            id="payout.paused.title"
          />
        </h3>
        <p className='text-white/[0.6] text-xs leading-[18px] mb-4'>
          <FormattedMessage 
            id="payout.paused" 
            values={{
              countdown: <Countdown 
                targetTime={targetTime}
                className='text-[#FF5F01] ml-1'
              />
            }}
          />
        </p>
        <p className='text-white/[0.6] text-xs leading-[18px] mb-4'>
          <FormattedMessage id="payout.paused.tip"/>
        </p>
        <div className="flex justify-end mt-6">
          <Button
            onClick={handleToggleModal} 
            type="button" className='ml-5 min-w-[88px]' 
            colorSchema='primary'
          >
            <FormattedMessage id="Done" />
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default PayoutsPausedCountdown;
