import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import cx from 'classnames';
import s from './Main.module.scss';

import { mainAccountsSelector, getAccountsDetailsBatchSelector } from '~/Accounts/Accounts.selectors';
import CardTitle from '@/shared/ui/CardTitle';
import AccountListItem from '~/Accounts/components/AccountListItem';
import SkeletonAccountListItem from '~/Accounts/components/SkeletonAccountListItem';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';

type MainProps = {}

const Main = (props: MainProps) => {
  const {} = props;

  const intl = useIntl();
  const { minerAccountOrBatch, isSupport } = useSupportMiner();
  const mainAccount = useSelector(state => mainAccountsSelector(state, minerAccountOrBatch));
  const { isSuccess } = useSelector(state => getAccountsDetailsBatchSelector(state, minerAccountOrBatch));
  
  return (
    <div className={s.root}>
      <CardTitle title={intl.formatMessage({ id: isSupport ? 'Mining account' : 'Main.title' })} />
      {isSuccess 
        ? <AccountListItem
          account={mainAccount}
        />
        : <SkeletonAccountListItem/>
      }
    </div>
  );
}

export default Main;
