import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './AccountListItem.module.scss';

import type { Account } from '~/Accounts/types';
import { toggleActive } from '~/Accounts/Accounts.slice';
import { selectSelectedAccountId } from '~/Accounts/Accounts.selectors';
import { getFormattedPower } from '@/shared/utils/formatters';
import { sendGtmCustomEvent, gtmAccountType } from '@/processes/CookieBanner';
import TruncateTextWithTooltip from "@/shared/ui/TruncateTextWithTooltip";
import { useCurrency } from "@/processes/PoolStats";
import AccountListPendingItem from "./AccountListPendingItem";
import { useScrollIntoView } from '~/Accounts/hooks/useScrollIntoView';
import { useURLQuery } from '@/shared/hooks/useURLQuery';
import { accountCoins } from '@/shared/constants/common';

type AccountListItemProps = {
  account: Account,
};

const AccountListItem = (props: AccountListItemProps) => {
  const {
    account,
  } = props;

  const { navigate } = useURLQuery();

  const {
    id,
    name = '',
    about = '',
    isMain,
    deactivated = false,
    isPending = false,
  } = account;

  const { formatCurrencyAmount, addCurrencySumbol } = useCurrency();
  // calculate sum of hashrates
  let hashrate = 0;
  accountCoins.forEach((coin: string) => { 
    hashrate += _get(account, `${coin}.hashrate`, 0);
  });

  const dispatch = useDispatch();
  const { scroll } = useScrollIntoView();
  const selectedAccount = useSelector(selectSelectedAccountId);
  
  const isSelected = selectedAccount === account?.id;

  const handleClick = () => {  
    sendGtmCustomEvent({
      action: 'account_click',
      label: {
        account: gtmAccountType(isMain, deactivated),
      }
    });

    scroll('details');
    
    if (!isSelected) {
      navigate(`/${id}`, undefined, { shallow: true, scroll: false });
      dispatch(toggleActive(id));
      // keep payouts section opened if user selected coin
      // dispatch(toggleSelectedPayoutCoin(null));
    }
  }

  const avatarLetter = name[0] || '';
  // calculate sum of balances
  let balance = 0;
  accountCoins.forEach((coin: string) => { 
    const unpaid = _get(account, `${coin}.balances.unpaid`, 0);
    balance += formatCurrencyAmount(unpaid, coin);
  });
  const formattedBalance = addCurrencySumbol(balance);
  const formattedHashrate = getFormattedPower(hashrate);

  if (isPending) {
    return <AccountListPendingItem account={account}/>
  }

  return (
    <button className={cx(
      s.root,
      { 
        [s.active]: isSelected, 
        [s.main]: isMain, 
      },
    )} onClick={handleClick}>
      <div
        className={cx(s.avatar, 'font-light leading-3 flex items-center justify-center text-center p2 rounded-md')}
      >
        <div className='pt-1'>{avatarLetter}</div>
      </div>
      <div className={cx(s.texts, 'text-left flex-1 basis-1/2 font-medium')}>
        <TruncateTextWithTooltip classNameText={cx(s.name, 'leading-5 mb-1')} tooltipText={name}/>
        <TruncateTextWithTooltip classNameText={cx(s.description, 'text-xs')} tooltipText={about}/>
      </div>
      <div className={cx(s.values, 'text-right flex-1 basis-1/3 font-medium')}>
        <div className={cx(s.balance, 'leading-5 mb-1 whitespace-nowrap')}>
          {formattedBalance}
        </div>
        <div className={cx(s.hashrate, 'text-xs')}>
          {formattedHashrate}
        </div>
      </div>
    </button>
  );
}

export default AccountListItem;
