import _toLower from 'lodash/toLower';

import { POOL_URL } from '@/shared/constants/env';

export const LINKS = {
  WATCHER_LINKS: {
    page: '/watcher-links',
    create: '/watcher-links?modal=watcher-link',
    pool: (coin: string = 'BTC', account: string, token: string) => `${POOL_URL}/${_toLower(coin)}/dashboard?account=${account}&token=${token}`,
  },
};
