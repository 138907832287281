const IconToastClose = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.47.47a.75.75 0 011.06 0L8 6.94 14.47.47a.75.75 0 111.06 1.06L9.06 8l6.47 6.47a.75.75 0 11-1.06 1.06L8 9.06l-6.47 6.47a.75.75 0 01-1.06-1.06L6.94 8 .47 1.53a.75.75 0 010-1.06z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default IconToastClose;
