import React from 'react';
import _get from 'lodash/get';
import { FormattedMessage, injectIntl } from 'react-intl';

import Head from 'next/head'
import Header from '@/features/Header';
import MainLayout from '@/shared/layouts/MainLayout';
import ErrorPage from "@/shared/layouts/ErrorPage";

import cx from 'classnames';
import * as s from './ErrorBoundary.module.css';

class ErrorBoundary extends React.Component<any, any> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  // TODO: uncomment to reload page on error
  // handleReloadPage = () => {
  //   if (window && window.location) {
  //     window.location.reload();
  //   }
  // }

  // resetErrorState = () => {
  //   this.setState({ hasError: false })
  // }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Head>
            <title>Hiveon Business Portal</title>
            <meta name="description" content="" />
          </Head>
          <div className="flex flex-col h-screen">
            <Header/>
            <MainLayout className="grow flex items-center justify-center">
              <ErrorPage />
            </MainLayout>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);