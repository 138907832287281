import { useRouter } from 'next/router';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';

import { PAGE_ROUTES } from '@/shared/constants/routes';

export const useURLQuery = () => {
  const router = useRouter();
  const { query, asPath } = router;

  const isPageRoute = PAGE_ROUTES.some(({ path }: any) => asPath.startsWith(path));

  return {
    query,
    asPath,
    isPageRoute,
    navigate: (pathname: string, query = {}, options = {}) => {
      router.push({
        pathname,
        query,
      }, undefined, options);
    },
    navigateReplace: (pathname: string, query = {}) => {
      const currentQuery = router.query;
      if (router.asPath === pathname) { return; }
      router.replace({
        pathname,
        query: currentQuery,
      });
    },

    getURLQueryCoin: () => {
      const { query } = router;
      const querySource = query?.source || '';

      return (querySource as string).split("_")[1] || 'btc';
    },

    updateURLQueryParams: (newQueryParams = {}, merge = true) => {
      const currentQuery = router.query;
      const queryObject = merge ? { ...currentQuery, ...newQueryParams } : newQueryParams;
      const withFilteredValues = _omitBy(queryObject, _isNil);
      router.push({
        pathname: router.pathname,
        query: withFilteredValues,
      });
    },
  }
}

export default useURLQuery;