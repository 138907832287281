import { useGetCryptoCurrenciesQuery } from './api';

type CryptoCurrenciesProps = {
  children: React.ReactNode;
}

const CryptoCurrencies = (props: CryptoCurrenciesProps) => {
  const { children } = props;
  useGetCryptoCurrenciesQuery(null);
  return <>{children}</>;
}

export default CryptoCurrencies;
