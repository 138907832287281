export const ACCOUNT_NAME_REGEX = /^[a-zA-Z0-9_]+$/;
export const ACCOUNT_DESC_REGEX = /^([a-zA-Z0-9_ ]+)?$/;
export const InvalidFormatMessage = 'You can only use numbers, upper and lowercase letters, and underscore _';
export const InvalidAccountMessage = 'This name is not allowed.';

export const RESERVED_NAMES = [
  "hiveon",
  "hiveosbee",
  "hiveos",
  "hive",
  "hivebee",
  "admin",
  "root",
  "superuser",
  "superadmin",
  "dev",
  "developer",
  "bee",
  "watcher-link",
  "watcher-links",
];
export const RESERVED_PREFIXES_REGEX = new RegExp(`^(${RESERVED_NAMES.join('|')})`);

export const extractError = (fieldError: any = {}, customize: any = {}) => {
  const { message, type } = fieldError;
  return customize[type] || message || null;
}
