import { useEffect } from 'react';
import _get from 'lodash/get';
import { useGetUserinfoQuery } from './api';
import TextLoader from '@/shared/ui/TextLoader';
import { useURLQuery } from '@/shared/hooks/useURLQuery';

type AuthProps = {
  children: React.ReactNode;
}

const Auth = (props: AuthProps) => {
  const { children } = props;
  const { navigateReplace, query, isPageRoute } = useURLQuery();
  const { isSuccess, data: userData } = useGetUserinfoQuery(null);
  const username = _get(userData, 'username', null);
  const minerAccount = _get(query, 'slug.0', username) || username;
  const isSupport = _get(query, 'slug.1', '') === 'support';
  
  useEffect(() => {
    if (isSuccess && username && !isSupport && !isPageRoute) {
      navigateReplace(`/${minerAccount}`);
    }
  }, [isSuccess, username, isPageRoute]); // eslint-disable-line

  if (!isSuccess) { return <TextLoader width='100%' height='10px'/>; }
  
  return <>{children}</>;
}

export default Auth;
