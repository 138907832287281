import React from 'react';
import cx from 'classnames';
import { animated, useTransition } from 'react-spring';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import s from './Drawer.module.scss';

type DrawerProps = {
  show: boolean;  
  onDismiss?: () => void;
  direction?: 'left' | 'right' | 'top' | 'bottom';
  children?: React.ReactNode;
}

const Drawer = (props: DrawerProps) => {
  const {
    show = false,
    children,
    direction = 'bottom',
    onDismiss = () => {},
  } = props;

  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);
  const transitions = useTransition(show, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  });

  return <>
    {transitions(
      (styles, item) =>
        item && (
          <>
            <AnimatedDialogOverlay
              className={s.overlay}
              onDismiss={onDismiss}
              style={{ opacity: styles.opacity }}
            >
              <AnimatedDialogContent 
                className={cx(s.content, s[direction])}
                style={{
                  opacity: styles.opacity,
                  transform: styles.y.to(
                    (value) => `translate3d(0px, ${value}px, 0px)`
                  )
                }}
              >
                {children}
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          </>
      )
    )}
  </>
}

export default Drawer;
