const IconList = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1.95}
        y={3.41484}
        width={2.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <rect
        x={5.28325}
        y={3.41484}
        width={8.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <rect
        x={1.95}
        y={7.41484}
        width={2.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <rect
        x={5.28325}
        y={7.41484}
        width={8.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <rect
        x={1.95}
        y={11.4148}
        width={2.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
      <rect
        x={5.28325}
        y={11.4148}
        width={8.1}
        height={1.16667}
        rx={0.583333}
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.1}
      />
    </svg>
  )
}

export default IconList;
