import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import s from './PayoutsStats.module.scss';
import { 
  selectSelectedAccount, 
  selectSelectedPayoutCoin,
} from '~/Accounts/Accounts.selectors';
import { useCurrency } from "@/processes/PoolStats";
import { calculateExpectedEarnings } from '@/shared/utils/expectedEarnings';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import { formatCryptoAmount } from '@/shared/utils/cryptoFormatter';
import TruncateTextWithTooltip from "@/shared/ui/TruncateTextWithTooltip";

type PayoutsStatsProps = {}

const PayoutsStats = (props: PayoutsStatsProps) => {
  const intl = useIntl();
  const { minerAccountOrBatch } = useSupportMiner();
  const selectedAccount = useSelector(state => selectSelectedAccount(state, minerAccountOrBatch));
  const coin = useSelector(selectSelectedPayoutCoin);
  const { formatCurrency } = useCurrency();
  const currentAmount = _get(selectedAccount, `${coin}.balances.current`, 0);
  const available = _get(selectedAccount, `${coin}.balances.available`, 0);
  const unconfirmed = _get(selectedAccount, `${coin}.balances.unconfirmed`, 0);
  const estDailyEarnings = calculateExpectedEarnings(currentAmount);
  return (
    <div className={s.root}>
      <div className={cx(s.top, 'p-3')}>
        <div className={cx(s.title, 'mb-2 text-xs')}>
          <FormattedMessage id="PayoutsStats.title" />
        </div>

        <div className={cx(s.stat, 'flex justify-between items-end')}>
          <div className={cx(s.estBalances, 'overflow-hidden font-medium')}>
            <div className="flex items-baseline">
              <TruncateTextWithTooltip className={cx(s.estValue, 'mb-1 text-3xl mr-1 flex-grow')} tooltipText={formatCryptoAmount(estDailyEarnings, coin)}/>
              <span className={cx(s.muted, '')}>{coin}</span>
            </div>
            <div className={cx(s.estFiat, s.dimmed, 'text-xs')}>
              {`≈ ${formatCurrency(estDailyEarnings, coin)}`}
            </div>
          </div>
        </div>
      </div>

      <div className={cx(s.bottom, 'mt-px')}>
        <div className={cx(s.stat, 'flex justify-between items-start')}>
          <div className={cx(s.left, 'p-3 text-xs font-medium basis-1/2')}>
            <div className={cx('mb-1')}>
              <span className='text-sm xl:text-base mr-1'>{formatCryptoAmount(available, coin)}</span>
              <span className={s.muted}>{coin}</span>
            </div>
            <div className={cx(s.dimmed, 'mb-1')}>
              <FormattedMessage id="PayoutsStats.available" />
            </div>
            <div className={cx(s.dimmed, 'text-xs')}>
              {`≈ ${formatCurrency(available, coin)}`}
            </div>
          </div>

          <div className={cx(s.right, 'p-3 text-xs font-medium basis-1/2 text-left')}>
            <div className={cx('mb-1')}>
              <span className='text-sm xl:text-base mr-1'>{formatCryptoAmount(unconfirmed, coin)}</span>
              <span className={s.muted}>{coin}</span>
            </div>
            <div className={cx(s.dimmed, 'mb-1')}>
              <FormattedMessage id="PayoutsStats.unconfirmed" />
            </div>
            <div className={cx(s.dimmed, 'text-xs')}>
              {`≈ ${formatCurrency(unconfirmed, coin)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayoutsStats;
