import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux';
import {
  usernameSelector,
  emailSelector, 
} from '@/processes/Auth';
import Avatar from '@/shared/ui/Avatar';
import { IconExit } from '@/shared/ui/Icons';
import * as List from '@/shared/ui/List';
import {
  ACCOUNT_SETTINGS_LINK,
  ACCOUNT_CHANGE_PASSWORD_LINK,
  ACCOUNT_2FA_LINK
} from '@/shared/constants/env';
import { logoutWithRedirect } from '@/global/utils/apiHelpers';

type LoggedInContentProps = {}

const LoggedInContent = (props: LoggedInContentProps) => {
  const username = useSelector(usernameSelector);
  const email = useSelector(emailSelector);

  return (
    <>
      <div className="text-center my-8">
        <Avatar tag="span" name={username} size="xl" className="mb-3"/>
        <div className="text-white text-2xl mb-1 font-medium">{username}</div>
        <div className="text-[#A2B8DC]">{email}</div>
      </div>
      <List.Root className="mb-4">
        <List.Item target="_blank" href={ACCOUNT_SETTINGS_LINK}>
          <FormattedMessage id="user.acc.settings"/>
        </List.Item>
        <List.Item target="_blank" href={ACCOUNT_CHANGE_PASSWORD_LINK}>
          <FormattedMessage id="user.change.password"/>
        </List.Item>
        <List.Item target="_blank" href={ACCOUNT_2FA_LINK}>
          <FormattedMessage id="user.2fa"/>
        </List.Item>
      </List.Root>
      
      <List.Root>
        <List.Item className='text-[#FF504C]' href={logoutWithRedirect()}>
          <span className='inline-flex items-center'>
            <IconExit className="w-4 mr-2"/>
            <FormattedMessage id="user.log.out"/>
          </span>
        </List.Item>
      </List.Root>
    </>
  );
}

export default LoggedInContent;
