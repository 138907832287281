import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import cx from 'classnames';
import s from './CardTitle.module.scss';

export type CardTitleProps = {
  title: React.ReactNode;
  className?: string;
  inCard?: boolean;
  action?: React.ReactNode;
};

const CardTitle = (props: CardTitleProps) => {
  const {
    title = '',
    className = '',
    inCard = false,
    action,
  } = props;

  return (
    <div className={
      cx(
        s.root,
        className,
        {
          'px-3': !inCard,
          'mb-2': !inCard,
          'mb-1': inCard,
        },
        'flex justify-between items-center font-medium leading-4 text-xs',
      )
    }>
      <div className={cx(s.title)}>{title}</div>
      {
        action ? (
          <div className={s.action}>{action}</div>
        ) : null
      }
    </div>
  );
}

export default CardTitle;
