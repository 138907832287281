import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import _toUpper from "lodash/toUpper";
import _get from "lodash/get";
import _includes from 'lodash/includes';

import { gtmAttributes } from '@/processes/CookieBanner';
import { POOL_URL } from '@/shared/constants/env';
import useURLQuery from '@/shared/hooks/useURLQuery'
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import Back from '@/shared/ui/Back';

const BackToPool = () => {
  const { query, getURLQueryCoin } = useURLQuery();
  const [isShow, setIsShow] = useState(false);
  const { accountFromUrl, isSupport } = useSupportMiner();

  const queryCoin = getURLQueryCoin();

  useEffect(() => {
    if (_includes( _get(query, 'source', '') , 'pool')) {
      setIsShow(true);
    }
  }, [query]) // eslint-disable-line

  if (!isShow) { return <div/>; }

  const poolURL = `${POOL_URL}/${queryCoin}/dashboard?account=${accountFromUrl}${isSupport ? '&support=true': ''}`;

  return (
    <Back
      link={poolURL}
      target='_blank'
      {...gtmAttributes({
        action: 'back_to_pool_click',
      })}
    >
      <FormattedMessage 
        id={"back.to.pool"} 
        values={
          { coin: _toUpper(queryCoin) }
        }
      />
    </Back>
  );
}

export default BackToPool;
