import { DataLayerObjectType } from '../types';
import _isObject from 'lodash/isObject';

export const defaultGtmEventCategory = 'business_portal';

export const sendGtmCustomEvent = (dataLayerObject: DataLayerObjectType) => {
  const {
    category = defaultGtmEventCategory,
    action,
    label = '',
    value = '',
  } = dataLayerObject;
  if (!action) { return; }
  const gtmObject = {
    'event' : 'GAEvent',
    'eventCategory' : category,
    'eventAction' : action,
    'eventLabel': _isObject(label) ? JSON.stringify(label) : label,
  } as any;
  if (value) { gtmObject.eventValue = value; }
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push(gtmObject);
  }
}

export const gtmAttributes = (dataLayerObject: DataLayerObjectType) => {
  const {
    category = 'business_portal',
    action,
    label = '',
  } = dataLayerObject;
  if (!action) { return; }

  const gtmAttrs = {
    'data-gtm-category': category, 
    'data-gtm-action': action,
  } as any;

  if (label) {
    gtmAttrs['data-gtm-label'] = _isObject(label) ? JSON.stringify(label) : label;
  }

  return gtmAttrs;
}

export const gtmAccountType = (isMain = false, isDeactivated = false) => {
  if (isMain) {
    return 'main';
  } else if (isDeactivated) {
    return 'inactive';
  } else {
    return 'subaccount';
  }
}
