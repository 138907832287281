import { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';
import Input from '@/shared/ui/Input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useDispatch, useSelector } from 'react-redux';

import { selectShowCreateAccountModal } from '~/Accounts/Accounts.selectors';
import { toggleCreateAccountModal } from '~/Accounts/Accounts.slice';
import { 
  useCreateSubaccountMutation, 
  extendedApi,
  useLazyCheckAccountAvailabilityQuery, 
} from '~/Accounts/api';
import { DESCRIPTION_MAX_LENGTH } from '~/Accounts/constants/account';
import { isErrorResponse } from '@/global/utils/apiHelpers';
import { setConfirmationCallbackState } from '@/features/Confirmation';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import { 
  InvalidFormatMessage, 
  ACCOUNT_NAME_REGEX, 
  ACCOUNT_DESC_REGEX,
  RESERVED_PREFIXES_REGEX, 
  RESERVED_NAMES, 
  InvalidAccountMessage,
  extractError,
} from '~/Accounts/constants/validation';
import { writePendingAccountToStorage } from '~/Accounts/utils/pendingAccounts';
import { usernameSelector } from '@/processes/Auth';

type CreateSubAccountModalProps = {}

const schema = z.object({
  name: z.string()
    .regex(ACCOUNT_NAME_REGEX, InvalidFormatMessage)
    .min(3)
    .max(50)
    .refine((v) => {
      const isValid = !RESERVED_NAMES.includes(v) && !RESERVED_PREFIXES_REGEX.test(v);
      return isValid;
    }, InvalidAccountMessage),
  about: z.string()
    .regex(ACCOUNT_DESC_REGEX, InvalidFormatMessage)
    .min(0)
    .max(64),
});
type ValidationSchema = z.infer<typeof schema>;

const CreateSubAccountModal = (props: CreateSubAccountModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const username = useSelector(usernameSelector);
  const isShow = useSelector(selectShowCreateAccountModal);
  const [createSubaccountMutation, {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  }] = useCreateSubaccountMutation();
  const [checkAccountAvailability, { 
    isLoading: isAccountAvailabilityLoading 
  }] = useLazyCheckAccountAvailabilityQuery();
  const {
    handleSubmit,
    register,
    setError,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
  });

  const handleSubmitCallback = async (data: ValidationSchema) => {
    const { name } = data;
    const response = await checkAccountAvailability(name);
    const isAvailable = _get(response, 'data.available', false) === true;
    if (isAvailable) {
      setError('name', { message: intl.formatMessage({ id: 'error.not.available' }) })
      return;
    }
    dispatch(setConfirmationCallbackState({
      gtmLabel: 'add_subaccount',
      successMessage: intl.formatMessage({ id: 'notify.acc.added' }, {
        name,
      }),
      successCallback: (response: any) => {
        // reset form fields
        reset();
        dispatch(
          extendedApi.util.updateQueryData('getAccountsDetailsBatch', null, (draft) => {
            const { about, name } = response?.account || {};
            const existingIndex = draft.findIndex((item: any) => item.name === name);
            sendGtmCustomEvent({
              action: 'subaccount_created',
            });
            // write pending account to storage
            writePendingAccountToStorage(username, { about, name })
            // update if exits
            if (draft[existingIndex]) {
              Object.assign(draft[existingIndex], { about, name });
            // or, add new
            } else {
              draft.push({ about, name });
            }
          })
        )
      }
    }))
    createSubaccountMutation(data);
  }

  const handleOnClose = () => {
    sendGtmCustomEvent({ action: `add_subaccount_popup_close` });
    dispatch(toggleCreateAccountModal(false));
  }

  const handleOnCloseFromCancel = () => {
    sendGtmCustomEvent({ action: `add_subaccount_popup_cancel_click` });
    dispatch(toggleCreateAccountModal(false));
  }

  // TODO: move to hook or util
  useEffect(() => {
    if (isError && isErrorResponse(error)) {
      const errorMsg = error?.data?.message;
      Object.keys(schema._def.shape()).forEach((key: any) => {
        if (errorMsg.includes(key)) {
          setError(key, { message: errorMsg });
        }
      })
    }
  }, [isError, error]); // eslint-disable-line

  useEffect(() => {
    if (isShow) {
      sendGtmCustomEvent({ action: `add_subaccount_popup_show` });
    }
  }, [isShow]); // eslint-disable-line

  return (
    <Modal show={isShow} onDismiss={handleOnClose}>
      <h3 className='text-2xl text-white mb-4'>
        <FormattedMessage id="subacc.create.title" />
      </h3>
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Input
          className='mb-4'
          type="text"
          full={true}
          label={intl.formatMessage({ id: 'subacc.name' })}
          hint={intl.formatMessage({ id: 'subacc.name.tip' })}
          onClick={() => {
            sendGtmCustomEvent({ action: `add_subaccount_popup_name_click` });
          }}
          error={extractError(
            errors.name, 
            { too_small: intl.formatMessage({ id: 'error.min' }, { field: 'Name', count: 3 }) },
          )}
          {...register('name')}
        />
        <Input
          type="text"
          full={true}
          label={intl.formatMessage({ id: 'Description' })}
          labelPostfix={intl.formatMessage({ id: 'optional' })}
          error={extractError(errors.about)}
          maxLength={DESCRIPTION_MAX_LENGTH}
          onClick={() => {
            sendGtmCustomEvent({ action: `add_subaccount_popup_description_click` });
          }}
          {...register('about')}
        />
        <div className="flex justify-end mt-6">
          <Button onClick={handleOnCloseFromCancel} type="button" className='min-w-[88px]'><FormattedMessage id="Cancel" /></Button>
          <Button
            onClick={() => {
              const name_entered = !!getValues('name');
              sendGtmCustomEvent({ 
                action: `add_subaccount_popup_next_click`,
                label: {
                  name_entered,
                }
              });
            }}
            isLoading={isLoading || isAccountAvailabilityLoading}
            type="submit" 
            className='ml-5 min-w-[88px]' 
            colorSchema='primary'
          >
            <FormattedMessage id="Next" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default CreateSubAccountModal;
