import '@/shared/styles/globals.scss'
import type { AppProps } from 'next/app'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import en from '@/shared/locales/en.json';
import * as enExternal from '@/shared/locales/external/en.json';
import store from '@/global/store';
import Auth from '@/processes/Auth';
import Intercom from '@/processes/Intercom';
import { CryptoCurrencies } from '@/processes/CryptoCurrencies';
import CookieBanner from '@/processes/CookieBanner';
import ErrorBoundary from "@/shared/layouts/ErrorBoundary";
import Head from 'next/head'

const locale = 'en';
const messages = {
  ...en,
  ...enExternal,
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <IntlProvider messages={messages} locale={locale} defaultLocale={locale}>
        <ErrorBoundary>
          <Auth>
            <CryptoCurrencies>
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
              </Head>
              <Component {...pageProps} />
              <Intercom/>
              <CookieBanner 
                options={{
                  barTrackingID: 'UA-6395920-15',
                  barTrackingSite: 'pool',
                }}
              />
            </CryptoCurrencies>
          </Auth>
        </ErrorBoundary>
      </IntlProvider>
    </Provider>
  )
}
