import { useDispatch } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { switchView } from '~/Accounts/Accounts.slice';
import { ViewType } from '~/Accounts/types';

export const useScrollIntoView = () => {
  const dispatch = useDispatch();
  return {
    scroll: (view: ViewType) => {
      try {
        // hacky, but we need to auto scroll section for desktop
        if (window.matchMedia("(min-width: 641px)").matches) {
          // add timout, wait when element is visible
          setTimeout(() => {
            const scrollToEl = document.getElementById(`section-${view}`);
            if (scrollToEl) {
              scrollToEl.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 300);
        } else {
          // scroll to the page top before switching view
          animateScroll.scrollToTop({ duration: 300 });
        }
      } catch {}
      dispatch(switchView(view));
    }
  }
}
