import { FormattedMessage } from 'react-intl'
import cx from 'classnames';
import s from './ErrorPage.module.scss';


const ErrorPage = () => {

  return (
    <div className={s.root}>
      <h2 className="m-0 text-white text-2xl font-medium mb-6">
        <FormattedMessage id="ErrorPage.title" />
      </h2>
      <p className="text-white text-sm font-medium">
        <FormattedMessage id="ErrorPage.description" />
      </p>
    </div>
  );
}

export default ErrorPage;
