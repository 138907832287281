import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import s from './ButtonText.module.scss';

type ButtonTextProps = {
  type?: 'button' | 'submit';
  className?: string;
  children: React.ReactNode;
  [x:string]: any;
}

const ButtonText = ({
  type = 'button',
  className = '',
  children,
  ...restProps
}: ButtonTextProps) => {
  return (
    <button
      type={type}
      className={cx(className, 'button-base', s.root)}
      {...restProps}
    >
      {children}
    </button>
  );
}

export default ButtonText;
