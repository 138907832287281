import { useState } from "react";
import cx from 'classnames';
import s from './Disclosure.module.scss';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure";

type DisclosureProps = {
  panelClassName?: string,
  buttonClassName?: string,
  buttonContent: (isOpen?: boolean) => string | JSX.Element,
  panelContent: (isOpen?: boolean) => string | JSX.Element,
  onClick?: (...args: any) => void;
}

const ReachDisclosure = (props: DisclosureProps) => {
  const {
    buttonClassName,
    panelClassName,
    buttonContent,
    panelContent,
    onClick = () => {},
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <Disclosure open={open} onChange={() => {setOpen(!open)}}>
      <DisclosureButton 
        className={cx(buttonClassName, {[s.open]: open})}
        onClick={() => onClick(open)}
      >
        {buttonContent(open)}
      </DisclosureButton>
      <DisclosurePanel className={cx(s.panel, panelClassName)}>
        {panelContent()}
      </DisclosurePanel>
    </Disclosure>
  );
}

export default ReachDisclosure;
