import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import s from './Modal.module.scss';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { animated, useTransition } from 'react-spring';

type ModalProps = {
  show: boolean,
  onDismiss: () => void,
  className?: string;
  children: React.ReactNode,
}

const Modal = (props: ModalProps) => {
  const {
    show,
    onDismiss,
    className,
    children,
  } = props;

  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);
  const transitions = useTransition(show, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  });

  return (
    <>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay
              className={s.overlay}
              onDismiss={onDismiss}
              style={{ opacity: styles.opacity }}
            >
              <AnimatedDialogContent 
                className={s.content}
                style={{
                  transform: styles.y.to(
                    (value) => `translate3d(0px, ${value}px, 0px)`
                  )
                }}
              >
                {children}
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
        )
      )}
    </>
  );
}

export default Modal;
