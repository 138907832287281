const IconArrowDown = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11 9.854a.5.5 0 00.706 0L12.11 6.56a.5.5 0 11.707.707L9.524 10.56a1.5 1.5 0 01-2.122 0L4.11 7.268a.5.5 0 01.707-.707L8.11 9.854z"
        fill="currentColor"
      />
      <path
        d="M8.746 9.783a.4.4 0 01-.566 0L4.887 6.49a.6.6 0 10-.848.848l3.293 3.293a1.6 1.6 0 002.262 0l3.293-3.293a.6.6 0 00-.848-.848L8.746 9.783z"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconArrowDown
