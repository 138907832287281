import { readFromStorage, writeToStorage } from '@/shared/utils/storage';
import _remove from 'lodash/remove';
import type { Account } from '~/Accounts/types';

export const getPendingAccountStorageKey = (mainAccountName: string) => `${mainAccountName}.pendingAccounts`;

export const getPendingAccountsFromStorage = (mainAccountName: string) => {
  const accounts = readFromStorage(getPendingAccountStorageKey(mainAccountName));
  return accounts;
};

export const writePendingAccountToStorage = (mainAccountName: string, pendingAccount: { name: string, about?: string }) => {
  const storageKey = getPendingAccountStorageKey(mainAccountName);
  const existingAccounts = readFromStorage(storageKey);
  existingAccounts.push(pendingAccount);
  writeToStorage(storageKey, existingAccounts);
  return existingAccounts;
};

export const deletePendingAccountFromStorage = (mainAccountName: string, pendingAccountName: string) => {
  const storageKey = getPendingAccountStorageKey(mainAccountName);
  const pendingAccounts = readFromStorage(storageKey);
  _remove(pendingAccounts, (account: Account) => account.name === pendingAccountName);
  writeToStorage(storageKey, pendingAccounts);
  return pendingAccounts;
};

export const isAccountPendingInStorage = (mainAccountName: string, pendingAccountName: string) => {
  const accounts = readFromStorage(getPendingAccountStorageKey(mainAccountName));
  return accounts.findIndex((account: Account) => account.name === pendingAccountName) !== -1;
};
