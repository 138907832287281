import React, {ReactNode, RefObject} from 'react';
import { IconInfo } from '@/shared/ui/Icons';
import cx from 'classnames';
import s from './FieldWrapper.module.scss';

type FieldWrapperProps = {
  label?: string;
  labelInfo?: string;
  labelPostfix?: string;
  inputId?: string;
  hint?: string;
  className?: string;
  error?: string;
  full?: boolean;
  ref?: any;
  children: ReactNode;
}

const FieldWrapper = (props: FieldWrapperProps) => {
  const {
    label,
    labelInfo,
    labelPostfix,
    inputId,
    className,
    hint,
    error,
    full,
    ref,
    children,
  } = props;

  return (
    <div ref={ref} className={cx(s.root, className, {
      [s.full]: full,
      [s.hasError]: !!error,
    })}>
      {
        label ? (
          <label htmlFor={inputId} className={s.label}>
            <span className={s.labelText}>
              {label}
              {' '}
              {
                labelPostfix ? labelPostfix : null
              }
            </span>
            {
              labelInfo ? (
                <IconInfo name="info" className={s.infoIcon} />
              ) : null
            }
          </label>
        ) : null
      }
      {children}
      {
        error ? (
          <label htmlFor={inputId} className={s.error}>{error}</label>
        ) : null
      }
      {
        hint ? (
          <span className={s.hint}>{hint}</span>
        ) : null
      }
    </div>
  );
};

export default FieldWrapper;
