import {
  HeaderMobile,
  HeaderDesktop,
} from '~/Header'

export type HeaderProps = {
  className?: string;
}

const Header = (props: HeaderProps) => {
  return (
    <>
      <div className="h-[58px]" aria-hidden="true"/>
      <header className="max-w-[1536px] w-full mx-auto py-4 px-4 mobile-ls:py-4 sm:py-6 fixed left-0 right-0 top-0 z-50 bg-[#000018]/[0.95]">
        <HeaderMobile className="md:hidden" {...props}/>
        <HeaderDesktop className="hidden md:flex items-center justify-between" {...props}/> 
      </header>
    </>
  )
}

export default Header;
