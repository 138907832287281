import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';

import { setConfirmationCallbackState } from '@/features/Confirmation';
import { LINKS } from '@/shared/constants/links';
import { List, ListItem } from '@/shared/ui/List';
import Box from '@/shared/ui/Box';
import ButtonBase from '@/shared/ui/ButtonBase';
import Copy from '@/shared/ui/Copy';
import { IconDelete } from '@/shared/ui/Icons';
import { WL_TAG, extendedApi, useDeleteWatcherLinkMutation } from '~/WatcherLink/api';
import s from './WatcherLinkCard.module.scss';

type WatcherLinkCardProps = {
  wlData: any;
}

const WatcherLinkCard = ({
  wlData = {},
}: WatcherLinkCardProps) => {
  const {
    id,
    name,
    fullWl,
    account,
  } = wlData;

  const dispatch = useDispatch();
  const intl = useIntl();
  const [deleteWatcherLink, { isLoading }] = useDeleteWatcherLinkMutation();
  const poolWatcherLink = LINKS.WATCHER_LINKS.pool('BTC', account, id);

  const handleDeleteWatcherLink = () => {

    dispatch(setConfirmationCallbackState({
      gtmLabel: 'remove_watcher-link',
      successMessage: intl.formatMessage({ id: 'wl.deleted.notify' }, {
        name,
      }),
      successCallback: () => {
        dispatch(extendedApi.util.invalidateTags([WL_TAG]));
      }
    }));

    deleteWatcherLink(id);
  }

  return (
    <Box className={s.root}>
      <div className="flex items-center justify-between mb-3 mx-1.5">
        <h3 className="text-2xl">{name}</h3>
        <ButtonBase 
          disabled={isLoading} 
          onClick={handleDeleteWatcherLink}
        >
          <IconDelete className="w-4"/>
        </ButtonBase>
      </div>
      <List>
        <ListItem 
          tag={Link}
          theme="secondary"
          href={poolWatcherLink}
          isClickable={true}
          className={s.row}
          target="_blank"
        >
          <span className={s.label}>
            <FormattedMessage id="wl.link"/> 
          </span>
          <span className={s.value}>
            <span className={s.link}>{poolWatcherLink}</span>
            <Copy text={poolWatcherLink}/>
          </span>
        </ListItem>
        <ListItem 
          theme="secondary"
          tag="label"
          isClickable={true} 
          className={s.row}
        >
          <span className={s.label}>
            <FormattedMessage id="wl.api.key"/> 
          </span>
          <span className={s.value}>
            <span className={s.link}>{fullWl}</span>
            <Copy text={fullWl}/>
          </span>
        </ListItem>
        <ListItem theme="secondary" isClickable={false} className={s.row} tag="div">
          <span className={s.label}>
            <FormattedMessage id="wl.account"/> 
          </span>
          <span className={s.value}>
            {account}
          </span>
        </ListItem>
      </List>
    </Box>
  );
}

export default WatcherLinkCard;
