import { useMediaQuery } from 'react-responsive'

export const useBreakpoint = () => {
  const isMaxMediaSm = useMediaQuery({ maxWidth: 640 });
  const isMaxMediaMd = useMediaQuery({ maxWidth: 768 });
  const isMaxMediaLg = useMediaQuery({ maxWidth: 1024 });
  const isMaxMediaXl = useMediaQuery({ maxWidth: 1280 });

  const isMinMediaSm = useMediaQuery({ minWidth: 640 });
  const isMinMediaMd = useMediaQuery({ minWidth: 768 });
  const isMinMediaLg = useMediaQuery({ minWidth: 1024 });
  const isMinMediaXl = useMediaQuery({ minWidth: 1280 });

  return {
    isMaxMediaSm,
    isMaxMediaMd,
    isMaxMediaLg,
    isMaxMediaXl,
    isMinMediaSm,
    isMinMediaMd,
    isMinMediaLg,
    isMinMediaXl,
  };
};

export default useBreakpoint;
