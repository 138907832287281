import React, { useState, useEffect, forwardRef, ButtonHTMLAttributes } from 'react';
import cx from 'classnames';

interface ButtonBaseProps extends ButtonHTMLAttributes<any> {
  type?: 'button' | 'submit';
  className?: string;
  children: React.ReactNode;
  [x:string]: any;
}

const ButtonBase = forwardRef<any, ButtonBaseProps>(({
  type = 'button',
  className = '',
  children,
  ...restProps
}, ref) => {
  return (
    <button
      type={type}
      className={cx(className, 'button-base')}
      ref={ref}
      {...restProps}
    >
      {children}
    </button>
  );
});

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
