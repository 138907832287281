const IconToastError = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 0C4.501 0 .001 4.5.001 10s4.5 10 10 10C15.5 20 20 15.5 20 10S15.5 0 10 0zm4.701 13.3c.4.4.4 1 0 1.4-.2.2-.401.3-.701.3-.3 0-.499-.1-.7-.3L10 11.4l-3.3 3.3c-.2.2-.399.3-.699.3-.3 0-.5-.1-.701-.3a.968.968 0 010-1.4L8.6 10 5.3 6.7c-.4-.4-.4-1 0-1.4a.968.968 0 011.4 0L10 8.6l3.3-3.3c.4-.4 1-.4 1.401 0 .4.4.4 1 0 1.4l-3.3 3.3 3.3 3.3z"
      ></path>
    </svg>
  )
}

export default IconToastError;
