import { useBreakpoint } from '@/shared/hooks/useBreakpoint'
import {
  LoggedIn,
  LoggedInMobile,
} from './components/LoggedIn';

type LoginProps = {
  className?: string;
}

const Login = (props: LoginProps) => {
  const { className } = props;
  const { isMinMediaMd } = useBreakpoint()
  return isMinMediaMd 
    ? <LoggedIn className={className}/> 
    : <LoggedInMobile className={className}/>;
}

export default Login;
