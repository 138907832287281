import {useEffect, useState} from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './Payouts.module.scss';

import CoinLabel from '@/shared/ui/CoinLabel';
import CardTitle from '@/shared/ui/CardTitle';
import CardBlock from '~/Accounts/components/CardBlock';
import RowBlock from '~/Accounts/components/RowBlock';
import PayoutsStats from '~/Accounts/components/Payouts/components/PayoutsStats';
import PayoutsWallet from '~/Accounts/components/Payouts/components/PayoutsWallet';
import { 
  selectSelectedAccount, 
  selectSelectedPayoutCoin,
} from '~/Accounts/Accounts.selectors';
import { 
  useGetPayoutAddressQuery,
} from '~/Accounts/api';
import { toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { IconArrowLeft } from "@/shared/ui/Icons";
import ScrollToViewButton from "~/Accounts/components/ScrollToViewButton";
import Select from "@/shared/ui/Select";
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import AnimatedBlock from '@/shared/ui/AnimatedBlock';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';

type PayoutsProps = {
  className?: string;
  id?: string;
};

const thresholdOptions = {
  BTC: [
    { value: "0.005", label: "0.005" },
    { value: "0.008", label: "0.008" },
    { value: "0.016", label: "0.0016" },
  ],
  KAS: [
    { value: "100", label: "100" },
  ],
  DNX: [
    { value: "100", label: "100" },
  ]
}

const Payouts = (props: PayoutsProps) => {
  const {
    className = '',
    ...restProps
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();
  const { minerAccountOrBatch } = useSupportMiner();

  const selectedAccount = useSelector(state => selectSelectedAccount(state, minerAccountOrBatch));
  const coin = useSelector(selectSelectedPayoutCoin);
  const deactivated = _get(selectedAccount, 'deactivated', false);
  const accountName = _get(selectedAccount, 'name', null);
  const [threshold, setThreshold] = useState(_get(thresholdOptions, `${coin}[0]`, thresholdOptions.BTC[0]));
  useGetPayoutAddressQuery({ account: accountName, coin }, { skip: !(accountName && coin) });
  useEffect(() => {
    setThreshold(_get(thresholdOptions, `${coin}[0]`, thresholdOptions.BTC[0]));
  }, [coin]);

  const handleThresholdChange = (option: any) => {
    setThreshold(option);
    sendGtmCustomEvent({
      action: `payouts_treshhold_click`,
      label: {
        coin,
      }
    });
  };

  const onScrollBack = () => {
    dispatch(toggleSelectedPayoutCoin(null));
  }

  const isHidden = !accountName || !coin || deactivated;

  return (
    <AnimatedBlock
     show={!isHidden}
     className={cx(s.root, className)} 
    {...restProps}
    >
      <ScrollToViewButton
        view="details"
        className="px-3"
        onScroll={onScrollBack}
      >
        <IconArrowLeft className="mr-2"/> {_get(selectedAccount, 'name', '')}
      </ScrollToViewButton>


      <CardTitle title={intl.formatMessage({ id: 'Payouts.title', defaultMessage: 'Payouts' })} />
      
      <CardBlock>
        <div className={cx(s.title, 'text-2xl font-medium mb-5 flex justify-between items-start')}>
          <CoinLabel coin={coin} isShort />
        </div>

        <div className="mb-5">
          <PayoutsStats />
        </div>

        <RowBlock className='mb-5'>
          <div className='font-medium'>
            <FormattedMessage id="Payouts.threshold" defaultMessage="Threshold" />
          </div>

          <Select
            isDisabled={true}
            selectClassName="inline-flex"
            colorSchema="basic"
            options={_get(thresholdOptions, `${coin}`, thresholdOptions.BTC)}
            value={threshold}
            onChange={handleThresholdChange}
            styles={{
              menu: (base) => ({
                ...base,
                width: 'auto',
              }),
            }}
          />
        </RowBlock>

        <PayoutsWallet />
      </CardBlock>
    </AnimatedBlock>
  );
}

export default Payouts;
