const IconDelete = (props: any) => {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.264 2.883a1.3 1.3 0 011.285-1.1h1a1.3 1.3 0 011.285 1.1h-3.57zm-1.408 0A2.7 2.7 0 017.55.383h1a2.7 2.7 0 012.693 2.5h3.473a.7.7 0 110 1.4h-.966v10.633a2.7 2.7 0 01-2.7 2.7h-6a2.7 2.7 0 01-2.7-2.7V4.283h-.967a.7.7 0 110-1.4h3.474zm-1.107 1.4h8.6v10.633a1.3 1.3 0 01-1.3 1.3h-6a1.3 1.3 0 01-1.3-1.3V4.283zm5.966 2.766a.7.7 0 01.7.7v5.834a.7.7 0 01-1.4 0V7.749a.7.7 0 01.7-.7zm-2.633.7a.7.7 0 10-1.4 0v5.834a.7.7 0 101.4 0V7.749z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconDelete;
