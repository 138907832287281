
export const CONFIRMATION_CODE_LENGTH = 6;
export const CONFIRMATION_INTERVAL = 1000;

export const SECURITY_CODE_TYPES = {
  TWOFA: 'twofa',
  // TOTP is synonym keycloak value for TWOFA
  TOTP: 'totp',
  EMAIL: 'email',
};

export const TWOFA_TYPES = [
  SECURITY_CODE_TYPES.TWOFA, 
  SECURITY_CODE_TYPES.TOTP,
];
