import { useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { usernameSelector } from '@/processes/Auth';
import Avatar from '@/shared/ui/Avatar';
import Drawer from '@/shared/ui/Drawer';
import { SwipeHead } from '@/shared/ui/SwipeList';
import LoggedInContent from './LoggedInContent';

type LoggedInMobileProps = {
  className?: string;
}

const LoggedInMobile = (props: LoggedInMobileProps) => {
  const { className } = props;
  const username = useSelector(usernameSelector);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }

  return (
    <div className={className}>
      <Avatar 
        onClick={handleShowDrawer}
        type="button" 
        name={username}
      />

      <Drawer
        show={isOpen}
        onDismiss={handleHideDrawer}
      >
        <SwipeHead
          title={intl.formatMessage({ id: 'header.hid'})}
          action={<FormattedMessage id="header.cancel"/>}
          onDismiss={handleHideDrawer}
        />
        <div className="max-h-[70vh] overflow-y-auto">
          <LoggedInContent/>
        </div>
      </Drawer>
    </div>
  );
}

export default LoggedInMobile;
