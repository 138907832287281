import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import _get from 'lodash/get';
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowDeactivateAccountModal } from '~/Accounts/Accounts.selectors';
import { toggleDeactivateAccountModal } from '~/Accounts/Accounts.slice';
import { usernameSelector } from '@/processes/Auth';
import { selectSelectedAccount } from '~/Accounts/Accounts.selectors';
import { useDeactivateSubaccountMutation, extendedApi } from '~/Accounts/api';
import { setConfirmationCallbackState } from '@/features/Confirmation';
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import { accountCoins } from '@/shared/constants/common';

type DeactivateModalProps = {}

const DeactivateModal = (props: DeactivateModalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { minerAccountOrBatch } = useSupportMiner();
  const isShow = useSelector(selectShowDeactivateAccountModal);
  const selectedAccount = useSelector(state => selectSelectedAccount(state, minerAccountOrBatch));
  const accountName = useSelector(usernameSelector);
  const miningAccountName = _get(selectedAccount, 'name', '');
  // calculate sum of balances
  let balance = 0;
  accountCoins.forEach((coin: string) => {
    balance += _get(selectedAccount, `${coin}.balances.unpaid`, 0);
  });
  const isAccountWithBalance = balance > 0;

  const [
    deactivateSubaccount,
    {
      isLoading,
    }
  ] = useDeactivateSubaccountMutation();

  const handleOnClose = () => {
    sendGtmCustomEvent({ action: 'inactivate_popup_close' });
    dispatch(toggleDeactivateAccountModal(false));
  }

  const handleOnCloseFromCancel = () => {
    sendGtmCustomEvent({ action: 'inactivate_popup_cancel_click' });
    dispatch(toggleDeactivateAccountModal(false));
  }

  const handleDeactivate = (name: string) => {
    sendGtmCustomEvent({ action: 'inactivate_popup_deactivate_click' });
    dispatch(setConfirmationCallbackState({
      gtmLabel: 'inactivate',
      successMessage: intl.formatMessage({ id: 'notify.acc.deactivated' }, {
        name,
      }),
      successCallback: () => {
        sendGtmCustomEvent({ action: 'account_deactivated' });
        dispatch(
          extendedApi.util.updateQueryData('getAccountsDetailsBatch', null, (draft) => {
            const existingIndex = draft.findIndex((item: any) => item.name === name);
            // update if exits
            if (draft[existingIndex]) {
              Object.assign(draft[existingIndex], { deactivated: true });
            }
          })
        )
      }
    }))
    deactivateSubaccount(name);
  }

  useEffect(() => {
    if (isShow) {
      sendGtmCustomEvent({ action: 'inactivate_popup_show' });
    }
  }, [isShow]);

  return (
    <Modal show={isShow} onDismiss={handleOnClose}>
      <h3 className='text-2xl text-white mb-4'>
        <FormattedMessage 
          id="subacc.inactivate.title" 
          values={{
            miningAccountName,
          }}
        />
      </h3>

      <p className='text-white/[0.6] text-xs leading-[18px] mb-4'>
        <FormattedMessage 
          id="subacc.inactivate.desc" 
          values={{
            miningAccountName,
            br: <br/>,
            span: (chunks) => <span className="text-[#FF5F01]">{chunks}</span>
          }}
        />
      </p>
      {isAccountWithBalance && (
        <p className='text-white/[0.6] text-xs leading-[18px]'>
          <FormattedMessage 
            id="subacc.inactivate.desc.2" 
            values={{
              accountName,
              br: <br/>,
              span: (chunks) => <span className="text-[#FF5F01]">{chunks}</span>
            }}
          />
        </p>
      )}
      
      <div className="flex justify-end mt-6">
        <Button onClick={handleOnCloseFromCancel} type="button" className='min-w-[88px]'><FormattedMessage id="Cancel" /></Button>
        <Button 
          disabled={isLoading}
          onClick={handleDeactivate.bind(this, miningAccountName)}
          type="submit" className='ml-5 min-w-[88px]' 
          colorSchema='primary'
        >
          <FormattedMessage id="Deactivate" />
        </Button>
      </div>
    </Modal>
  );
}

export default DeactivateModal;
