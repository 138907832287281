import api from '@/global/api';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPoolStats: build.query({
      query: () => ({
        url: `/v1/stats/pool`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPoolStatsQuery,
} = extendedApi;

export const {
  getPoolStats,
} = extendedApi.endpoints;
