import { useState, useEffect } from 'react';
import cx from 'classnames';
import s from './RowBlock.module.scss';

/** 
 * Row List
 * ----------------------------------------------------------------
*/
type RowListProps = {
  children: React.ReactNode;
  className?: string;
};
const RowList = (props: RowListProps) => {
  const {
    children,
    className = '',
  } = props;

  return (
    <div className={cx(s.list, className)}>
      {children}
    </div>
  );
}

/** 
 * Row block
 * ----------------------------------------------------------------
*/
type RowBlockProps = {
  children: React.ReactNode;
  className?: string;
};

const RowBlock = (props: RowBlockProps) => {
  const {
    children,
    className = '',
  } = props;

  return (
    <div className={cx(s.item, className, 'px-4 py-3 flex justify-between items-center')}>
      {children}
    </div>
  );
}

export {
  RowList,
  RowBlock
}

export default RowBlock;
