import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import s from './CardBlock.module.scss';

type CardBlockProps = {
  children: React.ReactNode;
};

const CardBlock = (props: CardBlockProps) => {
  const {
    children,
  } = props;

  return (
    <div className={cx(s.root, 'p-4')}>
      {children}
    </div>
  );
}

export default CardBlock;
