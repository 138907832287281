import React from 'react';
import cx from 'classnames';
import s from './DropdownMenu.module.scss';
import {
  Menu,
  MenuItems,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuPopover,
} from "@reach/menu-button";
import {
  positionRight,
} from '@reach/popover';
import { IconMore } from "@/shared/ui/Icons";

type DropdownProps = {
  menuList: {
    text: string,
    href?: string,
    to?: string,
    onClick?: (e?: MouseEvent) => void,
    onSelect?: () => void,
  }[];
  buttonContent?: string | React.ReactNode;
  buttonClassName?: string;
  expandClassName?: string;
  itemClassName?: string;
  onClick?: (...args: any) => void;
}

const DropdownMenu = (props: DropdownProps) => {
  const {
    buttonContent,
    menuList,
    buttonClassName,
    expandClassName = '',
    itemClassName,
    onClick = () => {},
  } = props;

  return (
    <Menu>
      {({ isExpanded }) => (
        <>
          <MenuButton
            className={cx(buttonClassName || s.button, {
              [s.expand]: isExpanded,
              [expandClassName]: isExpanded,
            })}
            onClick={onClick}
          >
            {buttonContent ? buttonContent : <IconMore className={s.icon} width="24" height="24" />}
          </MenuButton>

          <MenuPopover className={s.list} position={positionRight}>
            <MenuItems>
              {menuList.map(({text, href, to, onClick, onSelect, ...restProps}: any, index: number) => (
                (href || to) ?
                  <MenuLink key={text + index}
                    href={href}
                    to={to}
                    className={cx(s.item, itemClassName)}
                    onClick={onClick}
                    onSelect={onSelect}
                    {...restProps}
                  >
                    {text}
                  </MenuLink>
                  :
                  <MenuItem
                    key={text + index}
                    className={cx(s.item, itemClassName)}
                    onClick={onClick}
                    onSelect={onSelect}
                    {...restProps}
                  >
                    {text}
                  </MenuItem>
              ))}
            </MenuItems>
          </MenuPopover>
        </>
      )}
    </Menu>
  );
}

export default DropdownMenu;
