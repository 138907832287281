import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './Details.module.scss';

import CardTitle from '@/shared/ui/CardTitle';
import DropdownMenu from '@/shared/ui/DropdownMenu';
import CardBlock from '~/Accounts/components/CardBlock';
import RowBlock from '~/Accounts/components/RowBlock';
import PayoutsCoinsList from '~/Accounts/components/Details/components/PayoutsCoinsList';
import Balances from './components/Balances';
import Hashrates from './components/Hashrates';
import { selectSelectedAccount, selectPayoutAddress } from '~/Accounts/Accounts.selectors';
import { getFormattedPower } from '@/shared/utils/formatters';
import { toggleDeactivateAccountModal } from '~/Accounts/Accounts.slice';
import { DESCRIPTION_MAX_LENGTH } from '~/Accounts/constants/account';
import { IconArrowLeft } from "@/shared/ui/Icons";
import { isErrorResponse, COMMON_ERROR } from "@/global/utils/apiHelpers";
import ScrollToViewButton from "~/Accounts/components/ScrollToViewButton";
import { 
  useUpdateSubaccountMutation,
} from '~/Accounts/api';
import { showSuccessToast, showErrorToast } from '@/shared/utils/notify';
import EditableDescription from "@/shared/ui/EditableDescription";
import { sendGtmCustomEvent, gtmAccountType } from '@/processes/CookieBanner';
import AnimatedBlock from '@/shared/ui/AnimatedBlock';
import { accountCoins } from '@/shared/constants/common';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';

type DetailsProps = {
  className?: string;
  id?: string;
}

const Details = (props: DetailsProps) => {
  const {
    className = '',
    ...restProps
  } = props;

  const dispatch = useDispatch();
  const { minerAccountOrBatch, isSupport } = useSupportMiner();
  const intl = useIntl();
  const selectedAccount = useSelector(state => selectSelectedAccount(state, minerAccountOrBatch));
  const {
    deactivated,
    isMain,
    balances = {},
    about = '',
    hashrate,
    name: accountName,
  } = selectedAccount;

  const handleInactivate = () => {
    sendGtmCustomEvent({
      action: 'details_settings_inactivate_click',
      label: {
        account: gtmAccountType(isMain, deactivated),
      }
    });
    dispatch(toggleDeactivateAccountModal(true))
  };

  const menuList = useMemo(() => ([
    {
      text: intl.formatMessage({ id: 'Details.inactivate', defaultMessage: 'Deactivate mining account' }),
      onSelect: handleInactivate,
    },
  ]), [deactivated]); // eslint-disable-line


  const formattedHashrate = getFormattedPower(hashrate);
  const [updateSubaccount, { isSuccess }] = useUpdateSubaccountMutation();
  
  const handleEdit = async(about: string) => {
    const response = await updateSubaccount({ name: accountName, about, isSupport });
    if ("error" in response && isErrorResponse(response.error)) {
      showErrorToast(intl.formatMessage({ id: response.error.data?.message || COMMON_ERROR }));
    } else {
      showSuccessToast(intl.formatMessage({ id: 'notify.acc.updated' }, {
        name: accountName,
      }));
    }
  }

  return (
    <AnimatedBlock
      show={!!accountName}
      className={cx(s.root, className)} 
      {...restProps}
    >
      <ScrollToViewButton view="main" className="px-3">
        <IconArrowLeft className="mr-2"/> <FormattedMessage id="Mining account" defaultMessage="Mining account" />
      </ScrollToViewButton>

      <CardTitle title={intl.formatMessage({ id: 'Details.title', defaultMessage: 'Details' })} />

      <CardBlock>
        <div className={cx(s.title, 'text-2xl font-medium mb-5 flex justify-between items-start')}>
          <div>{accountName}</div>
          {!isMain && !deactivated ? (
            <div className='mt-1'>
              <DropdownMenu
                onClick={() => {
                  sendGtmCustomEvent({
                    action: 'details_settings_click',
                    label: {
                      account: gtmAccountType(isMain, deactivated),
                    }
                  });
                }}
                menuList={menuList}
              />
            </div>
          ) : null}
        </div>

        <RowBlock className={s.detailsRow}>
          <div className='font-medium'>
            <FormattedMessage id="Details.description" defaultMessage="Description" />
          </div>
          <EditableDescription
            onClick={() => {
              sendGtmCustomEvent({
                action: 'details_description_click',
                label: {
                  account: gtmAccountType(isMain, deactivated),
                }
              });
            }}
            maxLength={DESCRIPTION_MAX_LENGTH} 
            inputClassName="text-right" 
            description={_get(selectedAccount, 'about', '—')} 
            saveDescription={handleEdit} 
          />
        </RowBlock>

        <Balances account={selectedAccount}/>
        
        <Hashrates account={selectedAccount}/>

        {
          !deactivated ? (
            <>
              <CardTitle title={intl.formatMessage({ id: 'Details.distribution', defaultMessage: 'Distribution' })} inCard />
              <PayoutsCoinsList
                account={selectedAccount}
                coins={accountCoins}
              />
            </>
          ) : null
        }
      </CardBlock>
    </AnimatedBlock>
  );
}

export default Details;
