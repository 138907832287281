import { useId } from 'react';
import cx from 'classnames';
import s from './Select.module.scss';
import ReactSelect, { components as selectComponents, StylesConfig } from 'react-select';
import FieldWrapper from '../FieldWrapper';
import { IconChevronDown, IconClear, IconSortUnfold, IconCheckmark } from "@/shared/ui/Icons";

type SelectProps = {
  label?: string;
  labelInfo?: string;
  className?: string;
  selectClassName?: string;
  hint?: string;
  error?: string;
  name?: string;
  id?: string;
  full?: boolean;
  isDisabled?: boolean;
  options: { [key: string]: any }[];
  optionLabelName?: string;
  optionValueName?: string;
  value?: any;
  isLoading?: boolean;
  defaultMenuIsOpen?: boolean;
  placeholder?: string;
  styles?: StylesConfig;
  components?: { [key: string]: any };
  colorSchema?: 'default' | 'basic';
  size?: 'sm' | 'md';
  isMulti?: boolean;
  isSearchable?: boolean;
  ref?: any;
  onChange?: (option: any) => void;
  [x:string]: any;
}

// eslint-disable-next-line react/display-name
export const Option = (props: any) => {
  const { children, ...restProps } = props;
  return (
    <selectComponents.Option className={cx(props.getClassNames(), 'ui-select__option-with-checkmark')} {...restProps}>
      <IconCheckmark
        className='checkmark-icon'
      />
      {children}
    </selectComponents.Option>
  );
};

const Select = (props: SelectProps) => {
  const {
    label,
    labelInfo,
    className,
    selectClassName,
    hint,
    error,
    name,
    id,
    full,
    isDisabled,
    options,
    optionLabelName = 'label',
    optionValueName = 'value',
    value,
    isLoading,
    placeholder,
    styles,
    components,
    colorSchema = 'default',
    size = 'md',
    onChange,
    onClick,
    ref,
    isSearchable = false,
    ...restProps
  } = props;

  const body = typeof window !== 'undefined' ? window.document.body : null;

  const sizes = {
    sm: 'ui-select__sm',
    md: 'ui-select__md',
  };

  const colorSchemes = {
    'default': 'ui-select__default',
    'basic': 'ui-select__basic',
  };

  const classes = cx(
    'ui-select',
    selectClassName,
    sizes[size],
    colorSchemes[colorSchema],
  );

  const selectedValue = options.find((options) => options.value === value);

  return (
    <FieldWrapper
      label={label}
      labelInfo={labelInfo}
      className={className}
      hint={hint}
      error={error}
      full={full}
    >
      <div className={cx(s.inputWrap, {
        [s.hasError]: !!error,
        [s.full]: full,
      })}>
        <ReactSelect
          instanceId={useId()}
          value={selectedValue}
          styles={{
            menuPortal: (base: any) => {
              return ({
                ...base,
                marginTop: colorSchema === 'basic' ? '-25px' : null,
              })
            },
            menu: (base: any) => ({
              ...base,
            }),
            ...styles
        }}
          options={options}
          components={{
            DropdownIndicator: (props: any) => <DropdownIndicator {...props} colorSchema={colorSchema} />,
            MultiValueRemove,
            IndicatorSeparator: null,
            ClearIndicator: undefined,
            Option,
            ...components,
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
          getOptionLabel={option => option[optionLabelName]}
          placeholder={placeholder}
          getOptionValue={option => option[optionValueName]}
          className={classes}
          classNamePrefix="ui-select"
          ref={ref}
          menuPortalTarget={body}
          onChange={onChange}
          isSearchable={isSearchable}
          {...restProps}
        />
      </div>
    </FieldWrapper>
  );
}

const DropdownIndicator = (props: any) => {
  return (
    <selectComponents.DropdownIndicator {...props}>
      {props.colorSchema === 'basic' ?
        <IconSortUnfold/> :
        <IconChevronDown/>
      }
    </selectComponents.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <selectComponents.MultiValueRemove {...props}>
      <IconClear />
    </selectComponents.MultiValueRemove>
  );
};


export {selectComponents};

export default Select;