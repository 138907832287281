import React, { useState } from 'react';
import s from './EditableDescription.module.scss';
import cx from "classnames";
import TruncateTextWithTooltip from "@/shared/ui/TruncateTextWithTooltip";

const ENTER_KEY = 'Enter';

type EditableDescriptionProps = {
  description: string;
  saveDescription: (description: string) => void;
  className?: string;
  classNameTooltipRoot?: string;
  inputClassName?: string;
  maxLength?: number;
  onClick?: (...args: any) => void;
}

const EditableDescription: React.FC<EditableDescriptionProps> = (props) => {
  const {
    description,
    saveDescription,
    className,
    classNameTooltipRoot,
    inputClassName,
    maxLength = 64,
    onClick = () => {},
  } = props;

  const [isEditMode, setEditMode] = useState(false);
  const handleOnClick = () => {
    setEditMode(true);
    onClick();
  };

  const handleKeyDown = (e: any): void => {
    if (e.key === ENTER_KEY) {
      handleSave(e);
    }
  }

  const handleSave = (e: any) => {
    const value = e.target.value;
    if (value === '' && description === '—') {
      setEditMode(false);
      return; // Don't do anything if value is empty and description is already set to '—'
    }

    if(description !== value) {
      saveDescription(value);
    }
    setEditMode(false);
  };

  return (
    <div className={cx(s.root, className)}>
      {isEditMode ? (
        <input
          className={cx(s.input, inputClassName)}
          type="text"
          defaultValue={description}
          autoFocus
          onFocus={e => e.target.select()}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
        />
      ) : (
        <TruncateTextWithTooltip as="button" className={classNameTooltipRoot} classNameText={cx(s.name, 'text-xs cursor-pointer text-right border border-solid border-transparent h-[24px] leading-3 box-border rounded-md w-full')} onClick={handleOnClick} tooltipText={description}/>
      )}
    </div>
  );
};

export default EditableDescription;