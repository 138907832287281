import _isNumber from 'lodash/isNumber';

export const getPower = (l:number, fixed:number = 2, fallback:number = 0) => {
  let value = l;
  let s = 1;
  let u = 'H/s';

  let KH = 1e3;
  let MH = 1e6;
  let GH = 1e9;
  let TH = 1e12;
  let PH = 1e15;
  let EH = 1e18;
  let ZH = 1e21;

  if (value >= KH) { s = KH; u = 'KH/s'; }
  if (value >= MH) { s = MH; u = 'MH/s'; }
  if (value >= GH) { s = GH; u = 'GH/s'; }
  if (value >= TH) { s = TH; u = 'TH/s'; }
  if (value >= PH) { s = PH; u = 'PH/s'; }
  if (value >= EH) { s = EH; u = 'EH/s'; }
  if (value >= ZH) { s = ZH; u = 'ZH/s'; }

  const valueOutput = value/s;

  return {
      value: !isNaN(valueOutput) ? valueOutput.toFixed(fixed) : fallback,
      divide: s,
      suffix: u,
  }
};

export const getFormattedPower = (val: number) => {
  const { value, suffix } = getPower(val);

  return `${value} ${suffix}`;
};

export const thousandSeparator = (number:number, delimeter = ',') => {
  if (!_isNumber(number)) {
    number = Number(number);
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimeter);
}
