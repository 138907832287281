import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';

import { List, ListItem, ListScroll } from '@/shared/ui/List';
import { LINKS } from '@/shared/constants/links';
import Copy from '@/shared/ui/Copy';
import { IconList, IconPlus, IconLink, IconArrowDown } from '@/shared/ui/Icons';
import type { WatcherLink } from '~/WatcherLink/types';
import { useGetWatcherLinksQuery } from '~/WatcherLink/api';
import { useWatcherLink } from '~/WatcherLink/hooks/useWatcherLink';
import s from './WatcherLinkDropDown.module.scss';

type WatcherLinkDropDownCommonProps = {
  scrollClassName?: string;
}

const WatcherLinkDropDownCommon = ({
  scrollClassName = '',
}: WatcherLinkDropDownCommonProps) => {

  const { data: wlList = [], isLoading, isSuccess } = useGetWatcherLinksQuery(null);
  const isEmpty = isSuccess && !wlList?.length;

  const { toggleCreateWatcherLinkModal } = useWatcherLink();

  return (
    <>
      <List>
        {isEmpty ? null : (
          <ListScroll className={cx(s.scrollable, scrollClassName)}>
            {wlList.map(({ id, name, fullWl }: WatcherLink) => {
              return (
                <ListItem 
                  tag="label"
                  key={id} 
                  isClickable={true} 
                  className={s.item}
                >
                  <div className={s.group}>
                    <div className={s.title}>{name}</div>
                    <div className={s.link}>{fullWl}</div>
                  </div>
                  <Copy size={16} className={s.copyIcon} text={fullWl}/>
                </ListItem>
              )
            })}
          </ListScroll>
        )}
        <ListItem
          tag="button"
          onClick={() => toggleCreateWatcherLinkModal(true)}
          theme="tertiary"
        >
          <div className={s.itemAction}>
            <IconPlus/>
            <FormattedMessage id="wl.dd.create"/>
          </div>
        </ListItem>
        {isEmpty ? null : (
          <ListItem
            href={LINKS.WATCHER_LINKS.page}
            theme="tertiary"
          >
            <div className={s.itemAction}>
              <IconList/>
              <FormattedMessage id="wl.dd.view.all"/>
            </div>
          </ListItem>
        )}
      </List>
    </>
  );
}

export default WatcherLinkDropDownCommon;
