import { useRef, useState, useEffect } from 'react';
import _range from 'lodash/range';
import _size from 'lodash/size';
import cx from 'classnames';
import s from './InputCode.module.scss';

type InputCodeProps = {
  onComplete?: (code: string) => void,
  onChange?: (code: string) => void,
  label?: string,
  disabled?: boolean,
  len?: number,
  value?: any,
};

const InputCode = (props: InputCodeProps) => {
  const {
    onComplete,
    onChange,
    label,
    disabled,
    len = 6,
    value,
  } = props;

  const refs: any = {};
  const [values, setValues]: [any, any] = useState({});

  const isControllable = value !== undefined;

  useEffect(() => {
    if (value) {
      setValues(value);

      // Focus on first input when clear the code
      if (_size(value) === 0 && _size(values) === len) {
        refs[0]?.current.focus();
      }
    }
  }, [value, len, refs, values]);

  const onInputKeyDown = (idx: number) => (e: any) => {
    // Replacing value if exist
    if (e.target.value && e.keyCode >= 48 && e.keyCode <= 57) {
      const newValues = {
        ...values,
        [idx]: String.fromCharCode(e.keyCode),
      };

      if (isControllable) {
        onChange && onChange(newValues);
      } else {
        setValues(newValues);
      }

      if (Object.values(newValues).filter(v => !!v).length === len) {
        handleComplete(newValues);
      }

      goNext(idx, false);
      e.preventDefault();

      return;
    }

    if (e.keyCode === 8) {
      if (e.target.value) {
        const updatedValues = {
          ...values,
          [idx]: '',
        };

        if (isControllable) {
          onChange && onChange(updatedValues);
        } else {
          setValues(updatedValues);
        }
      } else {
        goPrev(idx, false);
      }
    }
    if (e.keyCode === 39) {
      goNext(idx, true);
    }
    if (e.keyCode === 37) {
      goPrev(idx, true);
    }
  };

  const onInputChange = (idx: number) => (e: any) => {
    const newValues = {
      ...values,
      [idx]: e.target.value,
    };

    if (isControllable) {
      onChange && onChange(newValues);
    } else {
      setValues(newValues);
    }
    // console.log('onChange: ', Object.values(newValues).join(''));

    if (Object.values(newValues).filter(v => !!v).length === len) {
      handleComplete(newValues);
    }

    e.target.value && goNext(idx);
  };

  const onInputPaste = (idx: number) => (e: any) => {
    const pasteText = e.clipboardData.getData('Text');
    e.preventDefault();

    if (pasteText.length) {
      const sliced = pasteText.split('').slice(0, len);
      const newValues = sliced.reduce((acc: any, val: string, idx: number) => {
        acc[idx] = val;

        return acc;
      }, {});

      if (isControllable) {
        onChange && onChange(newValues);
      } else {
        setValues(newValues);
      }
      refs[len - 1].current.focus();

      if (sliced.length === len) {
        handleComplete(newValues);
      }
      // console.log('onInputPaste: ', pasteText, newValues, sliced, values);
    }
  };

  const handleComplete = async (newValues: any) => {
    const completeValues = newValues || values;
    onComplete && onComplete(Object.values(completeValues).join(''));
  };

  const goNext = (idx: number, circle?: boolean) => {
    let nextIdx = idx < (len - 1)
      ? idx + 1
      : circle
        ? 0
        : idx;

    refs[nextIdx].current.focus();
  };

  const goPrev = (idx: number, circle?: boolean) => {
    let nextIdx = idx > 0
      ? idx - 1
      : circle
        ? len - 1
        : idx;

    refs[nextIdx].current.focus();
  };

  return (
    <div className={s.root}>
      {
        label ? (
          <span className={s.label}>{label}</span>
        ) : null
      }

      <div className={cx(s.inputs, 'flex gap-2')}>
        {
          _range(len).map((idx) => {
            // eslint-disable-next-line
            !refs[idx] && (refs[idx] = useRef(null));

            return (
              <input
                ref={refs[idx]}
                key={idx}
                type={'number'}
                maxLength={1}
                disabled={disabled}
                onKeyDown={onInputKeyDown(idx)}
                onChange={onInputChange(idx)}
                onPaste={onInputPaste(idx)}
                value={values[idx] || ''}
                className={cx(s.input, 'block w-full max-w-14 h-14 text-xl leading-5 font-medium p-4 text-center outline-none outline-0 rounded-md m-0')}
                inputMode='decimal'
              />
            );
          })
        }
      </div>
    </div>
  );
}

export default InputCode;
