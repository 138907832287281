import { useSelector } from 'react-redux';
import { selectCoinAddressValidationRegexp } from '@/processes/CryptoCurrencies';

export const isAddressValid = (address: string, regexpStr: string): boolean => {
  // if for some reason we didn't get data for FE validation, return true, to validate address on backed
  if (!regexpStr) { return true; }
  
  const ruleRegexp = new RegExp(regexpStr);
  return ruleRegexp.test(address);
}

export const useValidateAddress = (props: { coin: string; }) => {
  const { coin } = props;
  const regexp = useSelector(state => selectCoinAddressValidationRegexp(state, coin));

  return {
    isAddressValid: (address: string) => {
      return isAddressValid(address, regexp);
    }
  }
}
