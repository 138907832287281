import { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from '@/shared/ui/Modal';
import Button from '@/shared/ui/Button';
import Input from '@/shared/ui/Input';
import Select from '@/shared/ui/Select';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useDispatch, useSelector } from 'react-redux';

import { setConfirmationCallbackState } from '@/features/Confirmation';
import { isErrorResponse, COMMON_ERROR } from "@/global/utils/apiHelpers";
import { showSuccessToast, showErrorToast } from '@/shared/utils/notify';
import { activeAndMainAccountsSelector } from '~/Accounts/Accounts.selectors';
import { useGetAccountsDetailsBatchQuery } from '~/Accounts/api';
import { WL_TAG, extendedApi, useCreateWatcherLinkMutation } from '~/WatcherLink/api';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import { ACCESS_LEVEL_VIEW_POOL_ALL } from '~/WatcherLink/constants/acl';

type WatcherLinkCreateModalProps = {
  show?: boolean,
  onClose?: () => void,
}

const schema = z.object({
  name: z.string().min(1),
  account: z.string().min(1),
});
type ValidationSchema = z.infer<typeof schema>;

const WatcherLinkCreateModal = ({
  show = false,
  onClose = () => {},
}: WatcherLinkCreateModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { minerAccountOrBatch } = useSupportMiner();

  // fetch all accounts or single account (for support team) 
  // and access data via selectors
  useGetAccountsDetailsBatchQuery(minerAccountOrBatch);
  const [createWatcherLink, {
    isLoading,
  }] = useCreateWatcherLinkMutation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
  });

  const activeAccounts = useSelector(activeAndMainAccountsSelector);
  const accountOptions = activeAccounts.map(({ id }: any) => ({ value: id, label: id }));
   
  const handleSubmitCallback = async (data: ValidationSchema) => {
    const { name, account } = data;
    const access = ACCESS_LEVEL_VIEW_POOL_ALL;
    const payload = { 
      name, 
      account, 
      access,
    };

    dispatch(setConfirmationCallbackState({
      gtmLabel: 'add_watcher-link',
      successMessage: intl.formatMessage({ id: 'wl.created.notify' }, {
        name,
      }),
      successCallback: () => {
        dispatch(extendedApi.util.invalidateTags([WL_TAG]));
        // reset form fields
        reset();
        // close modal
        handleOnClose();
      }
    }));

    createWatcherLink(payload);
  }

  const handleOnClose = () => { 
    onClose(); 
  }

  return (
    <Modal show={show} onDismiss={handleOnClose}>
      <h3 className='text-2xl text-white mb-4'>
        <FormattedMessage id="wl.create.title" />
      </h3>
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Input
          className='mb-4'
          type="text"
          full={true}
          label={intl.formatMessage({ id: 'wl.create.name' })}
          placeholder={intl.formatMessage({ id: 'wl.create.name.placeholder' })}
          error={errors?.name?.message}
          {...register('name')}
        />
         <Controller
            control={control}
            name="account"
            render={({ field = {} }: any) => {
              const { onChange, value, ref } = field;
              return (
                <Select
                  full={true}
                  label={intl.formatMessage({ id: 'wl.create.account' })}
                  placeholder={intl.formatMessage({ id: 'wl.create.account.placeholder' })}
                  options={accountOptions}
                  value={value}
                  onChange={(option) => { onChange(option.value); }}
                  menuPortalTarget={document?.body}
                  menuPosition="fixed"
                  inputRef={ref}
                  error={errors?.account?.message}
                />
              )
            }}
        />
        <div className="flex justify-end mt-6">
          <Button 
            onClick={handleOnClose} 
            type="button" 
            className='min-w-[88px]'
          >
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            isLoading={isLoading}
            type="submit" 
            className='ml-5 min-w-[88px]' 
            colorSchema='primary'
          >
            <FormattedMessage id="Next" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default WatcherLinkCreateModal;
