import _get from 'lodash/get';
import { useURLQuery } from '@/shared/hooks/useURLQuery';

const WL_MODAL_PARAM = 'watcher-link';

export const useWatcherLink = () => {
  const { query, updateURLQueryParams } = useURLQuery();

  const isShowCreateWatcherLink: boolean = _get(query, 'modal', '') === WL_MODAL_PARAM;

  return {
    isShowCreateWatcherLink,
    toggleCreateWatcherLinkModal: (show: boolean) => {
      updateURLQueryParams({ modal: show ? WL_MODAL_PARAM : null })
    }
  }
}
