import { IconBtc, IconKaspa, IconDynex } from '@/shared/ui/Icons';

export const accountCoins = [
  'BTC',
  // 'KAS', // shutodown kaspa
  // TODO: enable DNX by request
  // 'DNX',
];

export const DEFAULT_COIN = accountCoins[0];

export const COINS_MAP = {
  'BTC': {
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: IconBtc,
  },
  // 'KAS': {
  //   name: 'Kaspa',
  //   symbol: 'KAS',
  //   icon: IconKaspa,
  // },
  // TODO: enable DNX by request
  // 'DNX': {
  //   name: 'Dynex',
  //   symbol: 'DNX',
  //   icon: IconDynex,
  // },
};
