import { useIntl } from 'react-intl'
import _get from 'lodash/get';
import _isNaN from "lodash/isNaN";
import cx from 'classnames';
import CardTitle from '@/shared/ui/CardTitle';
import RowBlock, { RowList } from '~/Accounts/components/RowBlock';
import CoinLabel from '@/shared/ui/CoinLabel';
import { getFormattedPower } from '@/shared/utils/formatters';
import { accountCoins } from '@/shared/constants/common';

type HashratesProps = {
  account: any;
};

const Hashrates = (props: HashratesProps) => {
  const intl = useIntl();
  const { account } = props;
  const { deactivated } = account;

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'Details.hashrate', defaultMessage: 'Hashrate' })} inCard />
      <RowList className={cx({
          'mb-5': !deactivated,
      })}>
        {accountCoins.map((coin: string) => {
          let hashrate = Number(_get(account, `${coin}.hashrate`, 0));

          if (_isNaN(hashrate) || hashrate < 0) {
            hashrate = 0;
          }

          return (
            <RowBlock key={coin}>
              <div className='font-medium'>
                <CoinLabel isShort={true} coin={coin} />
              </div>
              <div className={cx('text-xs')}>
                {getFormattedPower(hashrate)}
              </div>
            </RowBlock>
          )
        })}
      </RowList>
    </>
  );
}

export default Hashrates;
