import api from '@/global/api';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';

export const WL_TAG = 'watcher-links';

export const extendedApi = api.enhanceEndpoints({
  addTagTypes: [WL_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getWatcherLinks: build.query({
      query: () => ({
        url: `/v1/watcher-links`,
        method: 'GET',
      }),
      providesTags: [WL_TAG],
      transformResponse: (response: any) => {
        return response?.watcherLinks || [];
      },
    }),
    createWatcherLink: build.mutation({
      query: (body: any) => ({
        url: `/v1/watcher-links`,
        method: 'POST',
        body,
      }),
    }),
    deleteWatcherLink: build.mutation({
      query: (id: string) => ({
        url: `/v1/watcher-links/${id}`,
        method: 'DELETE',
        body: {
          id,
        }
      }),
    }),
  }),
});

export const {
  useGetWatcherLinksQuery,
  useCreateWatcherLinkMutation,
  useDeleteWatcherLinkMutation,
} = extendedApi;

export const {
  getWatcherLinks,
  createWatcherLink,
  deleteWatcherLink,
} = extendedApi.endpoints;
