import { 
  createApi, 
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError, 
} from '@reduxjs/toolkit/query/react';
import { isErrorResponse, redirectWithReturnUrl } from '@/global/utils/apiHelpers';
import { API_PATH, BASE_HOST } from '@/shared/constants/env';
import _cloneDeep from 'lodash/cloneDeep';
import { 
  SECURITY_CODE_TYPES, 
  toggleConfimation,
} from '@/features/Confirmation';

const baseQuery = fetchBaseQuery({ 
  baseUrl: `${API_PATH}`,
  credentials: "include",
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

// store previous query, to re fetch it with security code
let lastConfirmationQuery: any = {};

const baseQueryWithConfirmation: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const { error } = result;
  // 401 unauthorized, redirect to login
  if (isErrorResponse(error) && error.status === 401) {
    const redirect = redirectWithReturnUrl({ 
      return_url: BASE_HOST,
      redirect: error.data.redirect
    });
    window.location.href = redirect;
  }

  // 403 confirmation required
  if (result.error && result.error.status === 403 && isErrorResponse(result.error)) {
    // clone query parameters
    lastConfirmationQuery = _cloneDeep({ args, api, extraOptions });
    // get confirmation type from header x-security-code-type
    const confirmationType = result.meta?.response?.headers.get('x-security-code-type') || '';
    // check if confirmation type is supported
    // @ts-ignore
    const isValidConfirmationType = Object.values(SECURITY_CODE_TYPES).includes(confirmationType);
    // dispatch action to show confirmation modal
    if (isValidConfirmationType) {
      api.dispatch(toggleConfimation({ isShow: true, confirmationType }));
    }
  }
  
  return result;
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithConfirmation,
  endpoints: (build) => ({
    fetchLatestConfirmationQuery: build.mutation({
      async queryFn(_args, _queryApi, _extraOptions, baseQuery) {
        const { args } = lastConfirmationQuery;
        // get previous query and add security code to headers
        const result = await baseQuery({
          ...args,
          ...{
            credentials: 'include',
            headers: {
              'x-security-code': _args.code,
            },
          },
        });
        return result;
      },
    }), 
  }),
});

export const {
  useFetchLatestConfirmationQueryMutation,
} = api;

export default api;
