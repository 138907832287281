import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import cx from 'classnames';
import s from './PayoutsCoinsList.module.scss';

// import { IconArrowDown } from '@/shared/ui/Icons';
// import { toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { selectSelectedPayoutCoin } from '~/Accounts/Accounts.selectors';
import PayoutsCoinsItem from '~/Accounts/components/Details/components/PayoutsCoinsItem';
// import { sendGtmCustomEvent, gtmAccountType } from '@/processes/CookieBanner';

type PayoutsCoinsListProps = {
  account: any,
  coins: any,
};

const PayoutsCoinsList = (props: PayoutsCoinsListProps) => {
  const {
    account,
    coins = [],
  } = props;
  const { isMain, deactivated } = account;

  const [isOpen, setIsOpen] = useState(true); // keep true untill we add more coins
  const selectedCoin = useSelector(selectSelectedPayoutCoin);
  const dispatch = useDispatch();

  // const toggleOpen = () => {
  //   sendGtmCustomEvent({
  //     action: 'payouts_click',
  //     label: {
  //       account: gtmAccountType(isMain, deactivated),
  //       action: isOpen ? 'collapse' : 'expand',
  //     }
  //   });

  //   if (isOpen) {
  //     dispatch(toggleSelectedPayoutCoin(null));
  //   }

  //   setIsOpen(!isOpen);
  // };

  useEffect(() => {
    if (selectedCoin) {
      setIsOpen(true)
    }
  }, [selectedCoin])

  return (
    <div className={cx(s.root, {
      [s.open]: isOpen,
    })}>
      <button
        className={cx(s.toggle, 'px-4 py-3 flex justify-between items-center w-full')}
        // onClick={toggleOpen}
        aria-expanded={isOpen}
      >
        <div className='font-medium'>
          <FormattedMessage id="Details.payouts" defaultMessage="Payouts" />
        </div>
        {/* <div className={cx(s.muted, 'text-right')}>
          <IconArrowDown className={cx(s.arrowIcon, 'inline-block')} />
        </div> */}
      </button>
      {
        isOpen ? (
          <div className={s.list}>
            {
              coins.map((coin: string) => (
                <PayoutsCoinsItem
                  key={`payouts-coin${_get(account, 'id', '')}-${coin}`}
                  coin={coin}
                  account={account}
                  className={s.listItem}
                />
              ))
            }
          </div>
        ) : null
      }
    </div>
  );
}

export default PayoutsCoinsList;
