import useCountdown from '@/shared/hooks/useCountdown';
import { useIntl } from 'react-intl';

type CountdownProps = {
  targetTime: any;
  className?: string;
  onFinishCallback?: () => void;
};

const Countdown = (props: CountdownProps) => {
  const { 
    targetTime = 0,
    className = '',
    onFinishCallback = () => {},
  } = props;
  const intl = useIntl();
  const { days, hours, minutes, seconds, isFinished } = useCountdown(targetTime);
  const formattedDate = [
    `${days ? `${days}${intl.formatMessage({ id: "d" })} :` : ''}`, 
    `${hours ? `${hours}${intl.formatMessage({ id: "h" })} :` : ''}`, 
    `${minutes ? `${minutes}${intl.formatMessage({ id: "m" })} :` : ''}`, 
    `${seconds ? `${seconds}${intl.formatMessage({ id: "s" })}` : ''}`,
  ].join(' ');

  if (isFinished) { 
    onFinishCallback();
    return null; 
  }
  return (
    <span className={className}>
      {formattedDate}
    </span>
  );
}

export default Countdown;
