import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl'
import { selectPoolStats } from '@/processes/PoolStats';

export const useCurrency = () => {
  const poolStats = useSelector(selectPoolStats);
  const currency = 'USD';
  const intl = useIntl();

  const formatCurrencyAmount = (value: number, coin: string) => {
    const exchangeRate = _get(poolStats, `stats.${coin}.exchangeRates.${currency}`, 1);
    // if exchange rate is not valid (due to the api error), return ~
    if (typeof exchangeRate !== 'number' || !exchangeRate) {
      return 0;
    }

    const amount = Number(value) * exchangeRate;
    return amount;
  }

  const addCurrencySumbol = (value: number) => {
    return intl.formatNumber(value, { style: 'currency', currency, signDisplay: 'never' });
  }

  const formatCurrency = (value: string | number, coin: string) => {
    const amount = formatCurrencyAmount(Number(value), coin);
    if (typeof amount !== 'number' || !amount) {
      return '0.00$';
    }
    return addCurrencySumbol(amount);
  }

  return {
    formatCurrencyAmount,
    formatCurrency,
    addCurrencySumbol,
  }
}

export default useCurrency;