import { useEffect } from 'react';
import cx from 'classnames';
import _get from 'lodash/get';

import s from './Accounts.module.scss';
import Box from '@/shared/ui/Box';
import Main from '~/Accounts/components/Main';
import SubaccountsActive from '~/Accounts/components/SubaccountsActive';
import SubaccountsInactive from '~/Accounts/components/SubaccountsInactive';
import Details from '~/Accounts/components/Details';
import Payouts from '~/Accounts/components/Payouts';
import CreateSubAccountModal from '~/Accounts/components/CreateSubAccountModal';
import DeactivateModal from '~/Accounts/components/DeactivateModal';
import AddEditWithdrawalAddressModal from '~/Accounts/components/AddEditWithdrawalAddressModal';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAccountsDetailsBatchQuery } from '~/Accounts/api';
import { selectView } from '~/Accounts/Accounts.selectors';
import BackToPool from '~/Accounts/components/BackToPool';
import { useGetPoolStatsQuery } from '@/processes/PoolStats';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import { toggleActive, toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { usernameSelector } from '@/processes/Auth';
import { WatcherLinkNavButton } from '@/features/WatcherLink';

const Accounts = () => {
  const { minerAccountOrBatch, accountFromUrl, isSupport } = useSupportMiner();
  const dispatch = useDispatch();

  // fetch all accounts or single account (for support team) 
  // and access data via selectors
  useGetAccountsDetailsBatchQuery(minerAccountOrBatch);
  useGetPoolStatsQuery(null);

  const view = useSelector(selectView);
  const username = useSelector(usernameSelector);

  // // toggle preselected account
  useEffect(() => {
    if (username) {
      dispatch(toggleActive(accountFromUrl || username));
      dispatch(toggleSelectedPayoutCoin(null));
    }
  }, [username]); // eslint-disable-line

  return (
    <>
      <div className="flex justify-between mb-10 items-center mx-4">
        <BackToPool/>
        <Box withBorder={false}>
          <WatcherLinkNavButton/>
        </Box>
      </div>

      <div className={cx(s.row, s[view])} id="view-container">
        <div className={s.col} id="section-main">
          <div className="mb-6">
            <Main />
          </div>
          {isSupport ? null : (
            <div className="mb-6">
              <SubaccountsActive />
            </div>
          )}
          {isSupport ? null : (
            <div>
              <SubaccountsInactive />
            </div>
          )}
        </div>
        <Details className={s.col} id="section-details"/>
        <Payouts className={s.col} id="section-payouts"/>
      </div>
      
      {/* Create sub-account modal */}
      <CreateSubAccountModal/>
      {/* Deactivate sub-account modal */}
      <DeactivateModal/>
      {/* Add or Edit Withdrawal address modal */}
      <AddEditWithdrawalAddressModal/>
    </>
  );
}

export default Accounts;
