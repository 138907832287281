import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import cx from 'classnames';
import s from "./TruncateTextWithTooltip.module.scss";
import { useWindowSize } from 'react-use';
import Tooltip from "@/shared/ui/Tooltip";

type TruncateTextWithTooltip = {
  className?: string;
  classNameText?: string;
  tooltipText?: string;
  tooltipOptions?: any;
  lineClamp?: number;
  onClick?: () => void;
  CustomToggleComponent?: any;
  as?: React.ElementType;
}

const lineClampStyles = (lineClamp?: number) => (
  {
    display: "-webkit-box",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
    overflow: "hidden",
  }
);

const TruncateTextWithTooltip: React.FC<TruncateTextWithTooltip> = (props) => {

  const {
    className,
    classNameText,
    tooltipText = '',
    tooltipOptions,
    lineClamp,
    CustomToggleComponent ,
    onClick = () => {},
    as: Element = 'div'
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();

  const [hideTooltip, setHideTooltip] = useState<boolean>(false);
  const isLineClamp = useMemo(() => lineClamp !== undefined, [lineClamp]);

// Use effect to hide tooltip if text is not truncated by first render
  useEffect(() => {
    setHideTooltip(!(ref.current != null && ref.current?.clientWidth < ref.current?.scrollWidth));
  }, []);

  // Use effect to hide tooltip if text is not truncated by window resize
  useEffect(() => {
    const newHideTooltip = isLineClamp ?
      !(ref.current != null && ref.current?.clientHeight < ref.current?.scrollHeight) :
      !(ref.current != null && ref.current?.clientWidth < ref.current?.scrollWidth);

    if(newHideTooltip !== hideTooltip) {
      setHideTooltip(newHideTooltip);
    }
  }, [hideTooltip, windowWidth]);

  const showTooltipByWidth = useCallback(() => setHideTooltip(!(ref.current != null && ref.current?.clientWidth < ref.current?.scrollWidth)), [ref]);
  const showTooltipByHeight = useCallback(() => setHideTooltip(!(ref.current != null && ref.current?.clientHeight < ref.current?.scrollHeight)), [ref]);

  const onMouseEnter = useCallback(() => (
      isLineClamp ? showTooltipByHeight() : showTooltipByWidth()
    ),
    [isLineClamp, showTooltipByHeight, showTooltipByWidth]
  );

  const lineClampStyle = isLineClamp ? lineClampStyles(lineClamp) : {};

  return (
    <Tooltip
      {...tooltipOptions}
      hideTooltip={hideTooltip}
      className={cx(s.tooltip, className)}
      ToggleComponent={
        CustomToggleComponent ?
          <CustomToggleComponent
            forwardRef={ref}
            onMouseEnter={onMouseEnter}
            ellipsisCss={s.ellipsis}
            lineClampStyle={lineClampStyle}
            onClick={onClick}
          />
          :
          <Element
            ref={ref}
            style={lineClampStyle}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            className={cx(s.ellipsis, classNameText, {"cursor-pointer": !hideTooltip})}
          >
            {tooltipText}
          </Element>
      }
    >
      {tooltipText}
    </Tooltip>
  )
}

export default TruncateTextWithTooltip;
