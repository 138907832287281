import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';

import Drawer from '@/shared/ui/Drawer';
import { SwipeHead } from '@/shared/ui/SwipeList';
import ButtonBase from '@/shared/ui/ButtonBase';
import { IconLink, IconArrowDown } from '@/shared/ui/Icons';
import WatcherLinkDropDownCommon from './WatcherLinkDropDownCommon';
import s from './WatcherLinkDropDown.module.scss';

type WatcherLinkDropDownMobileProps = {
}

const WatcherLinkDropDownMobile = ({
}: WatcherLinkDropDownMobileProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const handleShowDrawer = () => { setIsOpen(true); }
  const handleHideDrawer = () => { setIsOpen(false); }
  return (
    <>
      <ButtonBase className={s.toggle} onClick={handleShowDrawer}>
        <IconLink className="w-4"/>
        <IconArrowDown className="w-4"/>
      </ButtonBase>
   
      <Drawer
        show={isOpen}
        onDismiss={handleHideDrawer}
      >
        <SwipeHead
          title={intl.formatMessage({ id: 'wl.title'})}
          action={<FormattedMessage id="header.cancel"/>}
          onDismiss={handleHideDrawer}
        />
        <div className="max-h-[70vh] overflow-y-auto">
          <WatcherLinkDropDownCommon scrollClassName='max-h-[50vh]'/>
        </div>
      </Drawer>
    </>
  );
}

export default WatcherLinkDropDownMobile;
