import React from 'react';
import { animated, useTransition } from 'react-spring';

type AnimatedBlockProps = {
  show: boolean;
  children: React.ReactNode;
  className?: string;
}

const AnimatedBlock = (props: AnimatedBlockProps) => {
  const {
    show = false,
    className = '',
    children,
    ...restProps
   } = props;

  const transitions = useTransition(show, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  });

  return (
    <>
      {transitions((styles, isOpen) => isOpen ? (
        <animated.div 
          className={className} 
          style={{ 
            opacity: styles.opacity, 
            transform: styles.y.to(
              (value) => `translate3d(0px, ${value}px, 0px)`
            )
          }}
          {...restProps}
        >
          {children}
        </animated.div>
      ) : null)}
    </>
  );
}

export default AnimatedBlock;
