import TextLoader from "@/shared/ui/TextLoader";

const WatcherLinkLoader = () => {

  return (
    <TextLoader width="100%" height="80px" />
  );
}

export default WatcherLinkLoader;
