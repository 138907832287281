export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || 'tcm3gu2e';

export const BASE_HOST = process.env.NEXT_PUBLIC_BASE_HOST || 'http://localhost:3000';
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || 'http://localhost:3000';
export const POOL_URL = process.env.NEXT_PUBLIC_POOL_URL || 'https://hiveon.net';
export const API_PATH = `${API_HOST}/api`;

export const IDP_HOST = process.env.NEXT_PUBLIC_IDP_HOST || 'https://id.hiveon.com';
export const LOGIN_LINK = process.env.NEXT_PUBLIC_LOGIN_LINK || `${IDP_HOST}/auth/realms/id/protocol/openid-connect/auth?client_id=account&response_type=code&scope=email`;
export const ACCOUNT_SETTINGS_LINK = `${IDP_HOST}/auth/realms/id/account`;
export const ACCOUNT_CHANGE_PASSWORD_LINK = `${IDP_HOST}/auth/realms/id/account/password`;
export const ACCOUNT_2FA_LINK = `${IDP_HOST}/auth/realms/id/account/totp`;