const IconPlus = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.339 2.152a.6.6 0 00-1.2 0v5.405H1.744a.6.6 0 100 1.2h5.395v5.395a.6.6 0 101.2 0V8.757h5.405a.6.6 0 000-1.2H8.339V2.152z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPlus;
