import { useWatcherLink } from '~/WatcherLink/hooks/useWatcherLink';

import WatcherLinkCreateModal from '~/WatcherLink/ui/WatcherLinkCreateModal';

export const WatcherLinkCreateModalController = () => {
  const { 
    isShowCreateWatcherLink, 
    toggleCreateWatcherLinkModal,
  } = useWatcherLink();

  return (
    <WatcherLinkCreateModal 
      show={isShowCreateWatcherLink}
      onClose={() => toggleCreateWatcherLinkModal(false)}
    />
  )
}

export default WatcherLinkCreateModalController;