import { API_HOST, POOL_URL } from '@/shared/constants/env';

/**
 * Type predicate to narrow an unknown error to an object
 */
type ErrorResponseType = {
  code: number;
  message: string;
  redirect?: string;
}

export const COMMON_ERROR = 'Unexpected error. Please try again or contact support';

export function isErrorResponse(
  error: unknown
): error is { data: ErrorResponseType } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data === 'object'
  )
}

export const redirectWithReturnUrl = (props: { 
  redirect?: string, 
  return_url: string, 
}) => {
  const { redirect, return_url } = props;
  return `${API_HOST}${redirect}&return_url=${encodeURIComponent(return_url)}`;
}

export const logoutWithRedirect = (redirect: string = POOL_URL) => {
  return `${API_HOST}/api/oauth/logout?redirect=${encodeURIComponent(redirect)}`;
}
