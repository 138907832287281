const IconBtc = (props: any) => {
  return (
    <svg
      width={'1em'}
      height={'1em'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.348 14.58c-1.425 5.717-7.217 9.193-12.927 7.767C3.704 20.922.228 15.13 1.654 9.421 3.079 3.703 8.863.228 14.58 1.653c5.71 1.417 9.193 7.21 7.768 12.927z"
        fill="url(#paint0_linear_3532_18879)"
      />
      <path
        d="M17.079 10.67c.208-1.416-.867-2.182-2.35-2.69l.483-1.926-1.166-.291-.467 1.875c-.309-.075-.625-.15-.942-.217l.467-1.883-1.167-.292-.475 1.917a51.91 51.91 0 01-.75-.175v-.009l-1.616-.4-.309 1.25s.867.2.85.209c.475.116.558.433.542.683l-.55 2.191c.033.009.075.017.125.042a.697.697 0 01-.125-.033l-.767 3.067c-.058.141-.208.358-.533.274.008.017-.85-.208-.85-.208l-.583 1.342 1.525.383c.283.075.558.142.833.217l-.483 1.941 1.166.292.484-1.925c.316.084.633.167.933.242l-.475 1.916 1.167.292.483-1.942c2 .375 3.5.226 4.125-1.583.508-1.45-.025-2.291-1.075-2.841.775-.175 1.35-.684 1.5-1.717zm-2.675 3.75c-.358 1.45-2.809.668-3.6.467l.641-2.575c.792.2 3.342.592 2.959 2.109zm.367-3.774c-.334 1.325-2.367.65-3.026.483l.584-2.333c.658.167 2.783.475 2.441 1.85z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3532_18879"
          x1={11.9949}
          y1={1.32838}
          x2={11.9949}
          y2={22.6647}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9AA4B" />
          <stop offset={1} stopColor="#F7931A" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconBtc;
