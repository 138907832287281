import { configureStore } from '@reduxjs/toolkit'
import { ConfirmationSlice } from '@/features/Confirmation';
import { AccountsSlice } from '@/features/Accounts';
import { api } from './api';

export function makeStore() {
  return configureStore({
    reducer: { 
      confirmation: ConfirmationSlice,
      accounts: AccountsSlice,
      [api.reducerPath]: api.reducer,
    },
    devTools: true,
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  })
}

const store = makeStore();
export default store;