import cx from 'classnames'
import Logo from './components/Logo';
import Login from './components/Login';
import { 
  ProductsDesktop,
  HeaderProps,
} from '~/Header'
import { WatcherLinkDropDownDesktop } from '@/features/WatcherLink';

const HeaderDesktop = (props: HeaderProps) => {
  const { 
    className = '',
  } = props;
  return (
    <div className={cx(className)}>
      <div className={cx(
        "flex items-center justify-start",
        "w-1/2"
      )}>
        <Logo className='flex items-center'/>
      </div>

      <div className={cx(
        "flex items-center justify-end",
        "w-1/2"
      )}>
        <WatcherLinkDropDownDesktop/>
        <ProductsDesktop className="mr-7"/>
        <Login/>
      </div>
    </div>
  );
}

export default HeaderDesktop;
