import { getCryptoCurrencies } from './api';
import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { CurrencyType, PlatformType } from './types';

export const selectCryptoCurrencies = (state: any) => { return getCryptoCurrencies.select(null)(state)?.data?.currencies ?? []; }

export const selectCurrencyByCoin:any = createSelector(
  [
    selectCryptoCurrencies,
    (state, coin) => coin,
  ],
  (currencies, coin) => {
    return currencies?.find((currency: CurrencyType) => currency.code === coin);
  }
);

export const selectPlatformsByCoin:any = createSelector(
  [
    selectCurrencyByCoin,
  ],
  (currencyData) => {
    return currencyData?.platforms || [];
  }
);

export const selectCoinAddressValidationRegexp:any = createSelector(
  [
    selectPlatformsByCoin,
    (state, coin) => coin,
  ],
  (platforms, coin) => {
    const platform = platforms.find((platformObj: PlatformType) => platformObj.platform === coin);
    return platform?.addressRegex || null;
  }
);
