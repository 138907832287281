import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import s from './PayoutsCoinsItem.module.scss';

import { IconArrowDown } from '@/shared/ui/Icons';
import { toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { selectSelectedPayoutCoin } from '~/Accounts/Accounts.selectors';
import CoinLabel from '@/shared/ui/CoinLabel';
import { Account } from '~/Accounts/types';
import { sendGtmCustomEvent, gtmAccountType } from '@/processes/CookieBanner';
import { useScrollIntoView } from '~/Accounts/hooks/useScrollIntoView';

type PayoutsCoinsItemProps = {
  coin: string,
  className: string,
  account: Account,
}

const PayoutsCoinsItem = (props: PayoutsCoinsItemProps) => {
  const {
    coin = '',
    className = '',
    account,
  } = props;
  const { isMain, deactivated } = account || {};
  const { scroll } = useScrollIntoView();

  const dispatch = useDispatch();
  const selectedCoin = useSelector(selectSelectedPayoutCoin);

  const isSelected = selectedCoin === coin;

  const handleClick = (_coin:string) => {
    sendGtmCustomEvent({
      action: 'payouts_coin_click',
      label: {
        account: gtmAccountType(isMain, deactivated),
        action: isSelected ? 'collapse' : 'expand',
        coin: _coin,
      }
    });

    if (isSelected) {
      dispatch(toggleSelectedPayoutCoin(null));
    } else {
      dispatch(toggleSelectedPayoutCoin(_coin));
      scroll('payouts');
    }
  }

  return (
    <button
      className={cx(
        s.root,
        className,
        {
          [s.active]: isSelected,
        },
        'px-4 py-3 flex leading-5 justify-between items-center w-full mt-px'
      )}
      onClick={() => handleClick(coin)}
    >
      <div className='font-medium'>
        <CoinLabel coin={coin} isShort />
      </div>
      <div className={cx(s.muted, 'text-right')}>
        <IconArrowDown className={cx(s.arrowIcon, 'inline-block -rotate-90')} />
      </div>
    </button>
  );
}

export default PayoutsCoinsItem;
