import { useRef } from "react";
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import s from './SubaccountsInactive.module.scss';

import type { Account } from '~/Accounts/types';
import CardTitle from '@/shared/ui/CardTitle';
import AccountListItem from '~/Accounts/components/AccountListItem';
import { selectSubInctiveCount, inactiveAccountsSelector } from '~/Accounts/Accounts.selectors';
import Disclosure from "@/shared/ui/Disclosure";
import { IconArrowDown } from "@/shared/ui/Icons";
import cx from "classnames";
import useScrollBoundaries from "@/shared/hooks/useScrollBoundaries";
import { sendGtmCustomEvent } from '@/processes/CookieBanner';

type SubaccountsInactiveProps = {}

const SubaccountsInactive = (props: SubaccountsInactiveProps) => {
  const {} = props;

  const intl = useIntl();
  const inactiveAccounts = useSelector(inactiveAccountsSelector);
  const count = useSelector(state => selectSubInctiveCount(state));
  return (
    <div className={s.root}>
      {!!count ?
        <Disclosure
          onClick={(isOpen) => {
            sendGtmCustomEvent({
              action: 'inactive_accounts_click',
              label: {
                action: isOpen ? 'collapse' : 'expand',
              }
            });
          }}
          buttonClassName="w-full outline-none"
          buttonContent={(isOpen) => (
            <CardTitle
              className={s.titleHover}
              title={intl.formatMessage({
                id: 'MiningAccountsInactive.title',
                defaultMessage: 'Inactive mining accounts ({count})'
              }, {count})}
              action={<IconArrowDown className={cx(s.arrowIcon, {[s.open]: isOpen})}/>}
            />
          )}
          panelContent={() => PanelContent(inactiveAccounts)}
        />
        :
        <CardTitle
          title={intl.formatMessage({
            id: 'MiningAccountsInactive.title',
            defaultMessage: 'Inactive mining accounts ({count})'
          }, {count})}
        />
      }
    </div>
  );
}

const PanelContent = (inactiveAccounts: any) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isNotAtStart, isNotAtEnd } = useScrollBoundaries(scrollContainerRef);

  return (
    <div
      ref={scrollContainerRef}
      className={cx(s.list, {
        [s.isScroll]: isNotAtStart,
        [s.isScrollEnd]: isNotAtEnd,
      })}
    >
      {
        (inactiveAccounts) ? inactiveAccounts.map((account: Account, idx: number) => (
          <AccountListItem
            key={`active-account-${idx}`}
            account={account}
          />
        )) : null
      }
    </div>
  )
}

export default SubaccountsInactive;
