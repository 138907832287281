const IconLink = (props: any) => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.314 15.185c.544-.15 1.038-.45 1.482-.899l2.494-2.489c.444-.443.743-.933.892-1.47.15-.54.15-1.081 0-1.622-.15-.547-.448-1.043-.892-1.486-.25-.25-.55-.449-.899-.598a3.877 3.877 0 00-1.028-.264l.895-.9c.434.079.794.196 1.08.349.307.161.574.35.802.567.609.607 1.01 1.275 1.208 2.003a3.93 3.93 0 010 2.169c-.203.723-.603 1.386-1.201 1.988l-2.615 2.609c-.604.602-1.268 1.003-1.992 1.206a3.987 3.987 0 01-2.183 0c-.723-.198-1.39-.601-2-1.214C.748 14.526.347 13.86.15 13.139a4.034 4.034 0 010-2.17c.203-.723.602-1.386 1.2-1.989l1.982-1.977c-.015.188-.012.381.01.58v.002c.035.278.1.54.197.787L2.208 9.716c-.444.443-.743.936-.893 1.477a2.967 2.967 0 000 1.616c.15.542.452 1.037.9 1.485.44.443.93.741 1.474.89a3.017 3.017 0 001.626 0zm.5-6.89c.15.542.45 1.034.9 1.477.245.256.543.458.89.607.297.126.64.214 1.03.264l-.887.9a4.15 4.15 0 01-1.09-.35 3.532 3.532 0 01-.8-.566c-.61-.613-1.011-1.28-1.209-2.002a4.034 4.034 0 010-2.17c.203-.723.603-1.386 1.201-1.988L8.472 1.85c.604-.597 1.265-.995 1.984-1.198a3.96 3.96 0 012.175 0c.73.198 1.398.598 2.007 1.206.61.614 1.012 1.282 1.21 2.004a3.933 3.933 0 010 2.17c-.199.722-.597 1.385-1.201 1.987L12.664 9.99a2.805 2.805 0 00-.016-.566 3.196 3.196 0 00-.19-.803l1.331-1.337c.444-.443.743-.932.893-1.469.154-.54.157-1.083.008-1.624-.15-.542-.451-1.037-.9-1.485-.445-.443-.938-.742-1.482-.89a3.016 3.016 0 00-1.626 0c-.544.149-1.035.45-1.474.899l-2.502 2.48c-.444.443-.743.935-.892 1.477-.15.54-.15 1.082 0 1.623z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconLink;
