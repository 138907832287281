import { useState } from 'react';
import { FormattedMessage } from 'react-intl'
import cx from 'classnames';
import * as List from '@/shared/ui/List';
import { IconProduct, IconArrowRight } from '@/shared/ui/Icons';
import * as Menubar from '@radix-ui/react-menubar';
import Box from '@/shared/ui/Box';
import { NAVIGATION_PRODUCTS_LOGGED_IN, NAVIGATION_MORE } from '~/Header/constants/navigation';
import s from './ProductsDesktop.module.scss';

type ProductsDesktopProps = {
  className?: string;
}

const ProductsDesktopMore = (props: any) => {
  return (
    <>
      {NAVIGATION_MORE.map(({ title, link }, idx) => {
        return (
          <Menubar.Item key={`${link}-${idx}`} asChild>
            <List.Item mb="xs" theme='secondary' className='' target="_blank" href={link}>
              <FormattedMessage id={title}/>
            </List.Item>
          </Menubar.Item>
        )
      })}
    </>
  )
}

const ProductsDesktop = (props: ProductsDesktopProps) => {
  const { 
    className = '',
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = () => { setIsOpen(!isOpen); }

  return (
    <Menubar.Root>
      <Menubar.Menu>
        <Menubar.Trigger asChild>
          <button
            className={cx(className, s.toggler)}
            onClick={handleToggleMenu}
            aria-expanded={isOpen}
            aria-controls="products-menu"
          >
            <IconProduct className="w-5 sm:mr-2"/>
            <FormattedMessage id="header.p"/>              
          </button>
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content 
            forceMount={true}
            sideOffset={15}
            asChild
            align="center"
          >
            <Box size="none" className="min-w-[200px] z-100">
              <List.Root className={s.navList}>
                {NAVIGATION_PRODUCTS_LOGGED_IN.map(({ title, link }, idx) => {
                  return (
                    <Menubar.Item key={`${link}-${idx}`} asChild>
                      <List.Item mb="xs" theme='secondary' className='' target="_blank" href={link}>
                        <FormattedMessage id={title}/>
                      </List.Item>
                    </Menubar.Item>
                  )
                })}
                <Menubar.Separator asChild>
                  <List.Separator className='my-2'/>
                </Menubar.Separator>
                
                <Menubar.Sub>
                  <Menubar.SubTrigger asChild>
                    <List.Item  mb="xs" theme='secondary' className='' tag="button">
                      <FormattedMessage id="header.more"/>
                      <IconArrowRight className="opacity-30 ml-2"/>
                    </List.Item>
                  </Menubar.SubTrigger>
                  <Menubar.Portal>
                    <Menubar.SubContent 
                      asChild
                      sideOffset={4}
                    > 
                      <Box size="none" className="min-w-[200px] z-100">
                        <List.Root className={s.navList}>
                          <ProductsDesktopMore/>
                        </List.Root>
                      </Box>
                    </Menubar.SubContent>
                  </Menubar.Portal>
                </Menubar.Sub>
              </List.Root>
            </Box>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}

export default ProductsDesktop;
