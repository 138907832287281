import cx from 'classnames';
import { FormattedMessage } from 'react-intl'
import Link from 'next/link';
import Image from 'next/image';

import logo from '@/shared/assets/logo.png';
import useMeta from '@/shared/hooks/useMeta';

type LogoProps = {
  className?: string;
}

const Logo = (props: LogoProps) => {
  const { className } = props;
  const { titleKey } = useMeta();
  return (
    <div className={cx('whitespace-nowrap', className)}>
      <Link href="/">
        <Image src={logo.src} width={23} height={25} alt="Hiveon" className='inline-flex items-center mr-2 -mt-1' />
        <span className='hidden sm:inline text-[#EEF0F2]/[0.5] font-medium'>Hiveon</span>
      </Link>
      <span className='hidden w-[2px] h-[18px] bg-[#D9D9D9]/[0.2] mx-4 sm:inline-flex'/>
      <FormattedMessage id={titleKey}/>
    </div>
  );
}

export default Logo;
