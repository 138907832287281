import { useURLQuery } from '@/shared/hooks/useURLQuery';
import _get from 'lodash/get';

export const useSupportMiner = () => {
  const { query } = useURLQuery();

    // extract miner account from url "/{account}/support"
    const minerAccount = _get(query, 'slug.0', '');
    const route = _get(query, 'slug.1', null);  
    const isSupport = route === 'support' && minerAccount;

    return {
      accountFromUrl: minerAccount,
      minerAccountOrBatch:  isSupport ? minerAccount : null,
      isSupport,
    }
}
