import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';

import Box from '@/shared/ui/Box';
import Popover from '@/shared/ui/Popover';
import ButtonBase from '@/shared/ui/ButtonBase';
import { IconLink, IconArrowDown } from '@/shared/ui/Icons';
import WatcherLinkDropDownCommon from './WatcherLinkDropDownCommon';
import s from './WatcherLinkDropDown.module.scss';

type WatcherLinkDropDownDesktopProps = {
}

const WatcherLinkDropDownDesktop = ({
}: WatcherLinkDropDownDesktopProps) => {
  return (
    <Popover
      triggerComponent={
        <ButtonBase className={s.toggle}>
          <IconLink className="w-4"/>
          <IconArrowDown className="w-4"/>
        </ButtonBase>
      }
      align="start"
      className='w-[200px] z-100'
    >
      <Box size="none">
        <WatcherLinkDropDownCommon scrollClassName='max-h-[220px]'/>
      </Box>
    </Popover>
  );
}

export default WatcherLinkDropDownDesktop;
