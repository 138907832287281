import { FormattedMessage, useIntl } from 'react-intl'
import Link from 'next/link';

import Button from "@/shared/ui/Button";
import { LINKS } from "@/shared/constants/links";

const WatcherLinkNavButton = () => {
  return (
    <Button tag={Link} size="md" href={LINKS.WATCHER_LINKS.page}>
      <FormattedMessage id="header.p.wl"/>
    </Button>
  );
}

export default WatcherLinkNavButton;
