import { getPoolStats } from './api';
import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

export const selectPoolStats = (state: any) => { return getPoolStats.select(null)(state)?.data ?? {}; }

export const selectExchangeRate:any = createSelector(
  selectPoolStats,
  (data) => _get(data, 'stats.BTC.exchangeRates', 0)
);
