import { createSelector } from '@reduxjs/toolkit';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _get from 'lodash/get';
import moment from "moment";
import { getAccountsDetailsBatch, getPayoutAddress } from './api';
import { usernameSelector } from '@/processes/Auth';
import { Account } from './types';
import { PAYOUT_PAUSE_IN_DAYS } from '~/Accounts/constants/payouts';
import { isAccountPendingInStorage } from '~/Accounts/utils/pendingAccounts';

// RTK Query selectors
// TODO: add createSelector
export const getAccountsDetailsBatchSelector = (state: any, accountName: any) => {
  return getAccountsDetailsBatch.select(accountName)(state) ?? {};
}

export const accountsSelector = (state: any, accountName: any = null) => {
  const username = usernameSelector(state);
  const accounts = getAccountsDetailsBatchSelector(state, accountName)?.data ?? [];
  // exclude main accounts from mining account
  return accounts.map((account: any) => {
    const { 
      name, 
      about,
      deactivated = false,
      ...restProps
    } = account;
    return Object.assign({
      id: name,
      name,
      about: about || '—',
      deactivated,
      isPending: isAccountPendingInStorage(username, account.name),
      isMain: account.name === username
    }, restProps);
  });
}

export const activeAccountsSelector = (state: any) => {
  return accountsSelector(state).filter((account: Account) => !account.deactivated && !account.isMain);
}

export const activeAndMainAccountsSelector = (state: any) => {
  return accountsSelector(state).filter((account: Account) => !account.deactivated);
}

export const inactiveAccountsSelector = (state: any) => {
  return accountsSelector(state).filter((account: Account) => account.deactivated && !account.isMain);
}

export const mainAccountsSelector = (state: any, accountName: any = null) => {
  return accountsSelector(state, accountName).find((account: Account) => account.isMain || account.name === accountName) || {};
}

export const accountNamesSelector:any = createSelector(
  accountsSelector,
  (accounts) => accounts.map((account: Account) => account.name),
);

// TODO: mock selectors, remove them later
export const selectSelectedAccountId = (state: any) => state.accounts.selectedAccount;
export const selectAllById = (state: any) => state.accounts.allById;
export const selectSelectedPayoutCoin = (state: any) => state.accounts.selectedPayoutCoin;
export const selectView = (state: any) => state.accounts.view;
export const selectShowCreateAccountModal = (state: any) => state.accounts.modals.showCreateAccount;
export const selectShowDeactivateAccountModal = (state: any) => state.accounts.modals.showDeactivateAccount;
export const selectShowWithdrawalAddressModal = (state: any) => {
  return {
    isShow: state.accounts.modals.showWithdrawalAddress,
    isEdit: state.accounts.modals.showWithdrawalAddressEdit,
  }
};

export const selectSelectedAccount = createSelector(
  [
    selectSelectedAccountId,
    accountsSelector,
  ],
  (selectedAccountId, accounts) => accounts.find((account: Account) => account.id === selectedAccountId) || {},
);

export const selectSubActiveCount:any = createSelector(
  activeAccountsSelector,
  (subActive) => _size(subActive),
);

export const selectSubInactive:any = createSelector(
  selectAllById,
  (allById) => {
    if (allById === null) {
      return null;
    }

    return _filter(allById, i => !i.isMain && i.deactivated);
  },
);
export const selectSubInctiveCount:any = createSelector(
  inactiveAccountsSelector,
  (subInactive) => _size(subInactive),
);

export const selectPayoutAddress = (state: any) => {
  const account = selectSelectedAccountId(state);
  const coin = selectSelectedPayoutCoin(state);
  const payoutAddressState =  getPayoutAddress.select({ account, coin })(state);
  const { isError, isLoading, isSuccess } = payoutAddressState;
  const { address: addressObject } = payoutAddressState?.data ?? {};
  
  const {
    address = '', 
    updatedAt = null, 
    memo = '',
    unlockAt,
  } = addressObject || {};
  const formattedPayoutsPausedTill = unlockAt ? moment(unlockAt).add(PAYOUT_PAUSE_IN_DAYS, 'days') : null;
  const isPayoutsPaused = unlockAt ? moment().isBefore(formattedPayoutsPausedTill) : false;
  return {
    address, 
    updatedAt, 
    coin, 
    memo,
    unlockAt,
    isPayoutsPaused,
    formattedPayoutsPausedTill,
    isError, 
    isLoading, 
    isSuccess,
    isCompleted: isSuccess || isError,
  };
}
