import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import s from './SubaccountsActive.module.scss';

import type { Account } from '~/Accounts/types';
import CardTitle from '@/shared/ui/CardTitle';
import AccountListItem from '~/Accounts/components/AccountListItem';
import SkeletonAccountListItem from '~/Accounts/components/SkeletonAccountListItem';
import { toggleCreateAccountModal } from '~/Accounts/Accounts.slice';
import { selectSubActiveCount, activeAccountsSelector, getAccountsDetailsBatchSelector } from '~/Accounts/Accounts.selectors';
import useScrollBoundaries from "@/shared/hooks/useScrollBoundaries";
import { sendGtmCustomEvent } from '@/processes/CookieBanner';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';

type SubaccountsActiveProps = {}

const SubaccountsActive = (props: SubaccountsActiveProps) => {
  const {} = props;

  const skeletonArray = new Array(4).fill(undefined);

  const { minerAccountOrBatch } = useSupportMiner();
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeAccounts = useSelector(activeAccountsSelector);
  const { isSuccess } = useSelector(state => getAccountsDetailsBatchSelector(state, minerAccountOrBatch));
  const count = useSelector(state => selectSubActiveCount(state));
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isNotAtStart, isNotAtEnd } = useScrollBoundaries(scrollContainerRef);
  const handleAddClick = () => {
    sendGtmCustomEvent({ action: 'add_subaccount_click', label: '' })
    dispatch(toggleCreateAccountModal(true));
  }

  return (
    <div className={s.root}>
      <CardTitle
        title={intl.formatMessage({ id: 'MiningAccountsActive.title', defaultMessage: 'Active mining accounts ({count})' }, { count })}
        action={
          <button
            className={cx(s.action, 'button-base cursor-pointer')}
            onClick={handleAddClick}
          >
            <FormattedMessage id='MiningAccountsActive.create' defaultMessage='Add' />
          </button>
        }
      />

      <div
        ref={scrollContainerRef}
        className={cx(s.list, {
          [s.isScroll]: isNotAtStart,
          [s.isScrollEnd]: isNotAtEnd,
        })}
      >
        {
          (isSuccess) ? activeAccounts.map((account:Account, idx:number) => (
            <AccountListItem
              key={`active-account-${idx}`}
              account={account}
            />
          )) : skeletonArray.map((_, idx:number) => (
            <SkeletonAccountListItem
              key={`skeleton-item-${idx}`}
            />
          ))
        }
      </div>
    </div>
  );
}

export default SubaccountsActive;
