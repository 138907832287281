import React from 'react';
import Link from 'next/link';

import { IconArrowLeft } from "@/shared/ui/Icons";

export type BackProps = {
  link?: string;
  children: React.ReactNode;
  [x:string]: any;
};

const Back = ({
  link = '/',
  children,
  ...restProps
}: BackProps) => {
  return (
    <Link 
      href={link}
      className="inline-flex items-center text-base leading-4 font-medium text-[#FF9500]"
      {...restProps}
    >
      <IconArrowLeft width={9} className="mr-2"/> 
      {children}
    </Link>
  );
}

export default Back;
