import Logo from './components/Logo';
import Login from './components/Login';
import { 
  ProductsMobile,
  HeaderProps,
} from '~/Header'
import { WatcherLinkDropDownMobile } from '@/features/WatcherLink';

const HeaderMobile = (props: HeaderProps) => {
  const {
    className = '',
  } = props;
  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <ProductsMobile />
        
        <Logo className='flex-grow flex items-center justify-center'/>
        
        <div className="w-1/5 flex items-center justify-end">
          <WatcherLinkDropDownMobile/>
          <Login/>
        </div>
      </div>
    </div>
  );
}

export default HeaderMobile;
