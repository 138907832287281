import s from './SkeletonAccountListItem.module.scss';
import TextLoader from "@/shared/ui/TextLoader";

const SkeletonAccountListItem = () => {

  return (
    <div className={s.root}>
      <TextLoader width="38px" height="38px" />

      <div className="flex flex-col gap-2 w-full">
        <TextLoader width="100%" height="19px" />
        <TextLoader width="100%" height="15px" />
      </div>
    </div>
  );
}

export default SkeletonAccountListItem;
